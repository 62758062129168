import { AxiosHeaders, RawAxiosRequestHeaders } from 'axios'

export namespace API {
  export enum TokenEndpoints {
    ME = 'user/me',
    INTRO_COMPLETED = 'user/me/intro-completed',
    OAUTH_TOKEN = 'oauth/token',
    REFRESH_TOKEN = 'refresh_token',
    SEND_LINK = 'password/send-link',
    PASSWORD_RESET = 'password/reset',
    REGISTER = 'register',
  }

  export enum BookingEndpoints {
    BOOKING_JOBS = 'booking/jobs',
    BOOKING_JOBS_PLUS = 'booking/jobs-plus',
    BOOKING_JOBS_LIST = 'booking/jobs?page=:page&per_page=:per_page',
    BOOKING_JOBS_LIST_ADDED = 'booking/jobs/added?page=:page&per_page=:per_page',
    BOOKING_JOBS_LIST_OWNED = 'booking/jobs/owned?page=:page&per_page=:per_page',
    BOOKING_JOBS_LIST_MANAGED = 'booking/jobs/managed?page=:page&per_page=:per_page',
    BOOKING_MY_JOBS = 'booking/jobs/my',
    BOOKING_EVENTS = 'event-types',
    BOOKING_JOB_DELIVERABLES = 'booking/jobs/:eid/deliverables',
    SHORTLIST_SHORTLIST_TALENT = 'booking/jobs/:eid/shortlist',
    BOOKING_PUBLISH = 'booking/jobs/:eid/publish',
    ASSIGN_TALENT = 'booking/jobs/assign',
    JOB_USERS = 'booking/jobs/:eid/users',
    SYNC_JOB_USERS = 'booking/jobs/:eid/sync-users',
    SYNC_DELIVERABLE_USERS = 'booking/deliverables/:eid/sync-users',
    GET_DELIVERABLE_USERS = 'booking/deliverables/:eid/users',
    LEAVE_JOB = 'booking/jobs/:eid/leave',
    BOOKING_COMPLETE = 'booking/jobs/:eid/complete',
    BOOKING_EXTERNAL_CREATE = 'booking/jobs/external',
    BOOKING_EXTERNAL = 'booking/jobs/external/:eid?token=:token',
    BOOKING_EXTERNAL_ACCEPT = 'booking/jobs/external/:eid/accept?token=:token',
    AGGREGATES = 'booking/jobs/aggregates',
    GENERATE_FILE_UPLOAD_URL = 'booking/jobs/:eid/generate-job-file-upload-url',
    GENERATE_FILE_DOWNLOAD_URL = 'booking/jobs/:eid/generate-job-file-download-url',
    ATTACH_FILES = 'booking/jobs/:eid/attachFiles',
    DETACH_FILES = 'booking/jobs/:eid/detachFiles',
    JOB_CAMPAIGN = 'jobs/:eid/campaign',
  }

  export enum CommentEndpoints {
    COMMENT = 'comment',
  }

  export enum GrantOptions {
    PASSWORD = 'password',
    REFRESH_TOKEN = 'refresh_token',
  }

  export enum WorkspaceEndpoints {
    CURRENT_USER_SETTINGS = 'workspace/current/user-settings',
    WORKSPACE = 'workspace',
    WORKSPACE_SETTINGS = 'workspace/settings',
    WORKSPACE_PUBLIC_SETTINGS = 'workspace/public-settings',
    WORKSPACE_CURRENT_SETTINGS = 'workspace/current',
    GENERATE_URL = 'workspace/generate-workspace-logo-image-upload-url?file_name=:file_name',
    LEAVE_WORKSPACE = 'workspace/:workspaceEid/leave-workspace',
  }

  export enum DeliverableEndpoints {
    DELIVERABLE = 'booking/deliverables',
    MULTIPLE = 'booking/deliverables/create-multiple',
    TYPES = 'booking/deliverable-types',
    MARK_COMPLETED = 'booking/deliverables/:deliverable/mark-completed',
    PUBLISH = 'booking/deliverables/:eid/publish',
    ASSIGN_TALENT = 'booking/deliverables/assign',
    GENERATE_FILE_UPLOAD_URL = 'booking/deliverables/:eid/generate-deliverable-file-upload-url',
    GENERATE_FILE_DONWLOAD_URL = 'booking/deliverables/:eid/generate-deliverable-file-download-url',
    ATTACH_FILES = 'booking/deliverables/:eid/attachFiles',
    DETACH_FILES = 'booking/deliverables/:eid/detachFiles',
  }

  export enum TalentEndpoints {
    TALENT = 'talent',
    TALENT_LIST = 'talent?page=:page&per_page=:per_page',
    TALENT_LIST_MANAGED = 'talent/managed?page=:page&job=:job&per_page=:per_page',
    TALENT_BY_EID = 'talent/:talentEid',
    TALENT_MANAGEMENT = 'talent/:eid/management',
    GENERATE_FILE_UPLOAD_URL = 'talent/generate-talent-file-upload-url?file_name=:file_name',
    GENERATE_PROFILE_IMAGE_UPLOAD_URL = 'talent/generate-profile-image-upload-url?file_name=:file_name',
    GENERATE_PROFILE_IMAGE_UPLOAD_URL_BY_ID = 'talent/:eid/generate-profile-image-upload-url?file_name=:file_name',
    LIST_TALENT_JOBS = 'talent/jobs/:talentid?page=:page&per_page=:per_page',
    GENERATE_TALENT_FILE_UPLOAD_URL = 'talent/:eid/generate-talent-file-upload-url',
    GENERATE_TALENT_FILE_DOWNLOAD_URL = 'talent/:eid/generate-talent-file-download-url',
    ATTACH_FILES = 'talent/:eid/attachFiles',
    DETACH_FILES = 'talent/:eid/detachFiles',
    SOCIALS = 'talent/:eid/socials',
    ADD_SOCIALS = 'talent/:eid/socials/auto',
  }

  export enum UserEndpoints {
    USER = 'user',
    UPDATE = 'user/:user',
    UPDATE_ROLES = 'user/:user/roles',
    ME = 'user/me',
    MY_WORKSPACES = 'user/me/workspaces',
    ATTACH_ROLES = 'user/:user/attach-roles',
    USER_LIST = 'user?page=:page&query[]=:query',
    GENERATE_PROFILE_IMAGE_UPLOAD_URL = 'user/generate-user-profile-image-upload-url?file_name=:file_name',
    CHANGE_PASSWORD = 'user/me/password',
    JOB_USER_LIST = 'user/job?page=:page&query[]=:query',
    TALENT_USER_LIST = 'user/talent?page=:page&query[]=:query',
    SET_DEFAULT_WORKSPACE = 'user/me/default-workspace',
    DEACTIVATE_USER = 'user/me/deactivate',
    DELETE_USER = 'user/me/delete-user',
  }

  export enum AgreementEndpoints {
    CREATE_JOB_AGREEMENT = 'booking/jobs/:job/agreements',
    CREATE_DELIVERABLE_AGREEMENT = 'booking/deliverables/:deliverable/agreements/:jobAgreement',
    UPDATE_JOB_AGREEMENT_FEE = 'booking/job-agreements/:jobAgreement',
    LIST_AGREEMENTS = 'booking/jobs/:job/job-agreements',
    MARK_AS_BOOKED = 'booking/job-agreements/:jobAgreement/deliverable-agreements/mark-booked',
    MARK_AS_ASSIGNED = 'booking/job-agreements/:jobAgreement/deliverable-agreements/mark-assigned',
    MARK_AS_REMOVED = 'booking/job-agreements/:jobAgreement/deliverable-agreements/mark-removed',
    MARK_AS_DONE = 'booking/job-agreements/:jobAgreement/deliverable-agreements/mark-done',
    COMPLETE_JOB_AGREEMENT = 'booking/job-agreements/:jobAgreement/mark-completed',
    UPDATE_MULTIPLE_AGREEMENTS = 'booking/deliverable-agreements-status',
    GENERATE_FILE_UPLOAD_URL = 'booking/job-agreements/:eid/generate-job-agreement-file-upload-url',
    GENERATE_FILE_DOWNLOAD_URL = 'booking/job-agreements/:eid/generate-job-agreement-file-download-url',
    ATTACH_FILES = 'booking/job-agreements/:eid/attachFiles',
    DETACH_FILES = 'booking/job-agreements/:eid/detachFiles',
  }

  export enum UserEndpoints {
    USERS = 'user',
  }

  export enum PermissionEndpoints {
    PERMISSION = 'permission/:permissionable_type/:permissionable_eid',
    USER_PERMISSION = 'permission/me/:permissionable_type/:permissionable_eid',
  }

  export enum InviteEndpoints {
    INVITES = 'invites',
    CREATE_INVITE = 'user/invite',
    VIEW_INVITE = 'invite/:invite?token=:token',
    REJECT_INVITE = 'invite/:invite/reject',
    ACCEPT_NEW_USER = 'invite/:invite/accept-new-user?token=:token',
    ACCEPT_EXISTING_USER = 'invite/:invite/accept',
  }

  export enum RolesEndpoints {
    LIST_ROLES = 'roles',
  }

  export enum OrganisationsEndpoints {
    BASE = 'organisations',
    UPDATE = 'organisations/:role',
    USERS = 'organisations/:role/users',
    ATTACH = 'organisations/:role/attach',
    GENERATE_URL = 'organisations/generate-logo-image-upload-url?file_name=:file_name',
  }

  export enum DepartmentsEndpoints {
    BASE = 'departments',
    UPDATE = 'departments/:role',
    USERS = 'departments/:role/users',
    ATTACH = 'departments/:role/attach',
    GENERATE_URL = 'departments/generate-logo-image-upload-url?file_name=:file_name',
  }

  export enum AuditEndpoints {
    AUDIT = 'audits/:auditable_type/:auditable_eid?page=:page&per_page=:per_page',
  }

  export enum NotificationEndpoints {
    NOTIFICATION = 'notification/feed',
    NOTIFICATION_UNREAD = 'notification/feed/my/unread-count',
    NOTIFICATION_UNSEEN = 'notification/feed/my/unseen-count',
    NOTIFICATION_READ = 'notification/feed/my/mark-as-read',
    NOTIFICATION_READ_ALL = 'notification/feed/my/mark-as-read/all',
    NOTIFICATION_SEEN_ALL = 'notification/feed/my/mark-as-seen/all',
    USER_NOTIFICATION_SETTINGS = 'notification/user-notification-settings',
  }

  export enum DashboardEndpoints {
    WIDGET_LIST = 'widget/:role',
    WIDGET_DATA = 'widget/:role/:widget?',
  }

  export enum SearchEndpoints {
    SEARCH = 'search?page=:page&per_page=:per_page&query=:query',
  }

  export interface iHttpClientRequestParameters<PayloadType> {
    endpoint: TokenEndpoints | BookingEndpoints | string
    headers?: RawAxiosRequestHeaders | AxiosHeaders
    payload?: PayloadType
    customWorkspace?: string
    params?: Record<string, any>
  }

  export enum ImportEndpoints {
    IMPORTER = 'importer',
    READ = 'importer/:eid',
    START = 'importer/:eid/start',
    CREATE = 'importer?type=:type',
    GENERATE_FILE_UPLOAD_URL = 'importer/generate-upload-url',
  }

  export enum IDPEndpoints {
    LIST = 'idp',
    CALLBACK = 'idp/:slug/callback',
    REDIRECT = 'idp/:slug/redirect',
  }

  export enum MentionEndpoints {
    GET = 'mention/:eid',
  }
}
