import { Avatar, DropdownOption, GlobalStyles, Icon } from '@andromeda'
import {
  Stack,
  Typography,
  Button,
  Pagination,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
} from '@mui/material'
import { getInitials } from '@utils/functions/helperFunctions'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listWorkspaceTalents, selectTalents } from '@redux/reducers/talentReducer'
import { Types } from '@orbit'
import { toast } from 'react-toastify'
import { assignTalentToDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { FilterOptions } from '@utils/types/filter'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { ModalHeader } from 'src/components/modal'
import { useQueryClient } from '@tanstack/react-query'
import { SearchFilter, iSearchFilters } from 'src/components/SearchFilter'
import { TABLE_HEADER_STYLES } from 'src/components/directory'
import { breakpoints } from 'src/andromeda/helpers/theme'
import addCustomFieldToOptions from '@utils/functions/addCustomFieldToOptions'
import PlayerAppearanceLevelRenderer from 'src/components/table/cells/playerAppearanceLevelRenderer'

const paperStyles = {
  border: `1px solid ${GlobalStyles.SLATE_100}`,
  borderRadius: '6px',
  display: 'flex',
}

export type AddTalentProps = {
  deliverableEid?: string
  assignedTalentEids?: string[]
  assignedTalents?: Partial<Types.Talent.iTalent>[]
  onSuccess?: (data?: any) => void
  onChange?: (talents: DropdownOption[]) => void
  context: 'inline-editor' | 'focus-panel'
  costMode?: Types.Job.iJobPayload['cost_mode']
}

export const AddTalent = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { talents, listMeta, listStatus } = useAppSelector(selectTalents)
  const { workspace, settings: workspaceSettings } = useAppSelector(selectWorkspace)

  const { options } = useContext(ModalContext)
  const { deliverableEid, assignedTalentEids, onSuccess, onChange, context, assignedTalents, costMode } = options?.modalProps?.[
    ModalPages.ASSIGN_TALENT
  ] ?? {
    deliverableEid: undefined,
    assignedTalentEids: undefined,
    assignedTalents: undefined,
    onSuccess: undefined,
    onChange: undefined,
    context: 'focus-panel',
    costMode: undefined,
  }

  const [page, setPage] = useState(1)
  const [selectedTalents, setSelectedTalents] = useState<Partial<Types.Talent.iTalent>[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [queryString, setQueryString] = useState<string>('')
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions[]>([])
  const [searchKeys, setSearchKeys] = useState<string[]>([])
  const [dropdownFilterOptions, setDropdownFilterOptions] = useState<iSearchFilters[]>([])
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)

  const pagination = useMemo(() => {
    if (listMeta?.pagination) {
      const currentPage = listMeta?.pagination.current_page || 0
      const totalPage = listMeta?.pagination.total_number_of_pages

      return {
        currentPage,
        totalPage,
        nextPage: currentPage + 1 > totalPage ? 0 : currentPage + 1,
        previousPage: currentPage - 1 <= 0 ? 0 : currentPage - 1,
      }
    }
  }, [listMeta])
  // @ts-expect-error
  const filteredTalents = useMemo<Types.Talent.iTalent[]>(() => {
    if (context === 'inline-editor') {
      return talents ?? []
    } else {
      return talents?.length
        ? talents.reduce<Types.Talent.iTalent[]>(
            // @ts-expect-error
            (acc, curr) => (!assignedTalentEids?.some((assignTalentEid) => assignTalentEid === curr.eid) ? [...acc, curr] : acc),
            []
          )
        : ([] as Types.Talent.iTalent[])
    }
  }, [assignedTalentEids, context, talents])

  useEffect(() => {
    if (context === 'inline-editor' && !selectedTalents.length && assignedTalents?.length) {
      setSelectedTalents(assignedTalents ?? [])
    }
  }, [assignedTalents, context, selectedTalents])

  useEffect(() => {
    dispatch(listWorkspaceTalents({ params: { page: page.toString(), per_page: '10' } }))
  }, [dispatch, page])

  const handleSelectTalent = (talent: Types.Talent.iTalent) => {
    const newSelectedTalents = [...selectedTalents, talent]
    if (context === 'inline-editor' && onChange) {
      if (costMode === 'PER_JOB') {
        onChange([talent])
        setSelectedTalents([talent])
      } else {
        setSelectedTalents(newSelectedTalents)
        onChange(newSelectedTalents.map(({ eid, display_name, profile_image }) => ({ eid, display_name, profile_image })))
      }
    } else {
      setSelectedTalents(newSelectedTalents)
    }
  }

  const _setFilterFromDropdown = (e: any, parentId: string) => {
    if (e.eid === 'all') {
      const newSelectedFilters = selectedFilters.filter((filter) => filter.parent_eid !== parentId)
      setSelectedFilters(newSelectedFilters)
    } else {
      if (selectedFilters.indexOf(e) === -1) setSelectedFilters([...selectedFilters, e])
    }
  }
  const _removeFilterFromDropdown = (index: number) => {
    const newSelectedFitlers: FilterOptions[] = []
    let key: FilterOptions | null = null
    selectedFilters.forEach((pill: any, pillIndex: number) => {
      if (index !== pillIndex) newSelectedFitlers.push(pill)
      else key = pill
    })

    let newParams: Types.Talent.iTalentListParams = { page: '1' }
    if (key) {
      const newSearchKeys: string[] = []
      searchKeys.forEach((str) => {
        if (str !== key?.value) newSearchKeys.push(str)
      })
      setSearchKeys(newSearchKeys)
      if (newSearchKeys.length) newParams.query = newSearchKeys
    }

    if (selectedFilters[index] && queryString === selectedFilters[index].value) {
      setQueryString('')
    }

    setSelectedFilters(newSelectedFitlers)
    dispatch(listWorkspaceTalents({ params: newParams }))
  }

  const clearFilters = () => {
    dispatch(listWorkspaceTalents({ params: { page: '1' } }))
    setQueryString('')
    setSelectedFilters([])
  }

  const handleAddTalents = async () => {
    if (context === 'inline-editor') {
      onSuccess?.()
    } else {
      setIsLoading(true)

      const agreement = selectedTalents?.reduce(
        (acc, talent) => (talent.eid ? [...acc, { talent_eid: talent.eid }] : acc),
        [] as Array<{ talent_eid: string }>
      )
      await toast.promise(
        dispatch(
          assignTalentToDeliverable({
            assign: [
              {
                deliverable_eid: deliverableEid as string,
                agreement,
              },
            ],
          })
        ).then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Talents assigned.')
            onSuccess?.(response.payload[0])
            setSelectedTalents([])

            queryClient.invalidateQueries({ queryKey: ['deliverable'], type: 'active' })

            trackEvent({
              event: TrackingEventEnums.Deliverable.DELIVERABLE_ADD_TALENT,
              eventProperties: {
                component_name: 'L2 Deliverable Panel',
                workspace: workspace,
              },
            })
          } else {
            toast.error('Failed to assign talents.')
          }
          setIsLoading(false)
        }),
        { pending: 'Assigning talent to deliverable...' }
      )
    }
  }

  useEffect(() => {
    const customFields = workspaceSettings?.custom_field_settings?.find((field) => field.section === 'talent')?.fields_settings || {}

    for (const key in customFields) {
      if (customFields[key].type.toLowerCase() === 'dropdown' && customFields[key].enabled && customFields[key].filterable) {
        setDropdownFilterOptions((prev) => {
          if (!prev.find((item) => item.key === key)) {
            return addCustomFieldToOptions(
              {
                ...customFields[key],
                key: customFields[key].key || key,
              },
              prev
            )
          }

          return prev
        })
      }
    }
  }, [workspaceSettings])

  return (
    <Stack width={{ xxs: '450px', sm: '650px' }}>
      <ModalHeader modalTitle="Add Talent" modalContext={ModalPages.ASSIGN_TALENT} />
      <SearchFilter
        centered
        searchPlaceholder="Search for Talent"
        searchKey="query"
        filters={dropdownFilterOptions}
        noFilterMsgText="No custom fields found. Please add them in workspace settings."
        onChangeFilter={(params) => {
          const { query, ...extraParams } = params
          const queryKeyword = typeof query === 'string' ? [query] : query
          dispatch(listWorkspaceTalents({ params: queryKeyword?.length ? { query: queryKeyword, ...extraParams } : extraParams }))
        }}
      />
      <Stack gap="14px" padding="12px" {...paperStyles}>
        <Typography color={GlobalStyles.SLATE_1000}>{`${listMeta?.pagination.total_results || 0} users match your criteria`}</Typography>
        <Stack {...paperStyles} maxHeight="calc(50vh - 57px)" sx={{ overflowY: 'auto' }}>
          {!filteredTalents?.length && listStatus === 'pending' ? (
            <Stack justifyContent="center" alignSelf="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Table sx={{ width: '100%' }} aria-label="simple table">
              {!isMobile && (
                <TableHead>
                  <TableRow sx={{ ...TABLE_HEADER_STYLES, borderTop: 'none' }}>
                    <TableCell sx={{ maxWidth: '88px', paddingLeft: '24px !important', paddingRight: '4px' }}>&nbsp;</TableCell>
                    <TableCell sx={{ paddingLeft: '8px' }}>NAME</TableCell>
                    <TableCell>PLAYER APPEARANCE LEVEL</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {filteredTalents?.map((talent, index) => {
                  const { eid, profile_image, display_name, role } = talent

                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          maxWidth: '88px',
                          paddingRight: '4px',
                          alignContent: isMobile ? 'flex-start' : 'center',
                        }}>
                        <Avatar
                          size={'md'}
                          image={typeof profile_image == 'string' ? profile_image : profile_image?.full_url}
                          imageStyle={{ backgroundColor: GlobalStyles.SLATE_800 }}
                          style={{ marginTop: isMobile ? '6px' : 0 }}
                          placeHolder={
                            <Typography fontSize="18px" color="white" textAlign="center">
                              {getInitials(display_name)}
                            </Typography>
                          }
                        />
                      </TableCell>

                      <TableCell sx={{ paddingLeft: '8px' }}>
                        <Stack justifyContent="center" flex={1} style={{ marginBottom: isMobile ? '10px' : 0 }}>
                          <Typography fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.MONO_BLACK}>
                            {display_name}
                          </Typography>
                          <Typography fontSize={`${GlobalStyles.FONT_SIZES.TINY}px`} color={GlobalStyles.SLATE_700}>
                            {role}
                          </Typography>
                        </Stack>

                        {isMobile && <PlayerAppearanceLevelRenderer talent={talent} />}
                      </TableCell>

                      {!isMobile && (
                        <TableCell>
                          <PlayerAppearanceLevelRenderer talent={talent} />
                        </TableCell>
                      )}

                      <TableCell>
                        {selectedTalents.some((selectedTalent) => selectedTalent.eid === eid) ? (
                          <Button
                            variant="contained"
                            sx={{ height: '40px', width: '40px', padding: 0 }}
                            aria-label={`Remove ${display_name} from selection`}
                            onClick={() => {
                              setSelectedTalents((prev) => prev.filter((x) => x.eid !== eid))
                            }}>
                            <Icon.Check size={14} />
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            sx={{ height: '40px', width: '40px', padding: 0 }}
                            aria-label={`Add ${display_name} to selection`}
                            onClick={() => {
                              handleSelectTalent(talent)
                            }}>
                            <Icon.Plus size={14} />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Stack>
        <Stack justifyContent="center">
          <Pagination
            page={page}
            size="medium"
            count={pagination?.totalPage}
            shape="rounded"
            onChange={(_, page) => {
              setPage(page)
            }}
            sx={{
              '&.MuiPagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <Button variant="contained" onClick={handleAddTalents} disabled={!selectedTalents.length || isLoading}>{`Confirm Selection ${
            selectedTalents.length ? `(${selectedTalents.length})` : ''
          }`}</Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
