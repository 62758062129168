import { MouseEvent, MouseEventHandler, useState } from 'react'

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose: MouseEventHandler<HTMLLIElement> | any = (e: MouseEvent<HTMLButtonElement> | any) => {
    e && e.stopPropagation()
    setAnchorEl(null)
  }

  return { anchorEl, open, handleClick, handleClose }
}
