import { Libraries, useJsApiLoader } from '@react-google-maps/api'
import { createContext } from 'react'

export const GoogleMapsApiContext = createContext({ isLoaded: false })

const libraries: Libraries = ['places']

export default function GoogleMapsApiProvider({ children }: { children: JSX.Element }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.googleMapsApiKey as string,
    libraries,
  })

  return <GoogleMapsApiContext.Provider value={{ isLoaded }}>{children}</GoogleMapsApiContext.Provider>
}
