/* eslint-disable @next/next/no-img-element */
import { GlobalStyles, Stack, View } from '@andromeda'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

interface ImageCropProps {
  imageSrc?: File | string | undefined
  key?: string
  enableAspect?: boolean
  onChange: (e: any) => void
  showCanvas?: boolean
  defaultWidth?: number
}

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const TO_RADIANS = Math.PI / 180
const rotate = 0
const scale = 1

/**
 * Image cropper
 * Accepts file/file path/url
 * @returns base64 image
 */
const ImageCrop = ({
  imageSrc,
  key = 'image-crop',
  enableAspect = true,
  showCanvas = false,
  defaultWidth = 300,
  ...props
}: ImageCropProps) => {
  const [crop, setCrop] = useState<Crop>()
  const imgRef = useRef<HTMLImageElement>(null)
  const [aspect, setAspect] = useState<number | undefined>(16 / 16)
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const canvasContainer = document.createElement('canvas')

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const drawCanvasDelay = 100
  useEffect(() => {
    const t = setTimeout(() => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        const c = completedCrop
        const image = imgRef.current
        const canvas = showCanvas ? previewCanvasRef.current : canvasContainer

        if (!showCanvas && canvas) {
          canvas.width = c.width
          canvas.height = c.height
        }

        if (canvas && completedCrop) {
          const ctx = canvas.getContext('2d')

          if (!ctx) {
            throw new Error('2d context not found')
          }

          if (image) {
            const scaleX = image.naturalWidth / image.width
            const scaleY = image.naturalHeight / image.height
            const pixelRatio = window.devicePixelRatio
            canvas.width = Math.floor(completedCrop.width * scaleX * pixelRatio)
            canvas.height = Math.floor(completedCrop.height * scaleY * pixelRatio)

            ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'

            const cropX = completedCrop.x * scaleX
            const cropY = completedCrop.y * scaleY

            const rotateRads = rotate * TO_RADIANS
            const centerX = image.naturalWidth / 2
            const centerY = image.naturalHeight / 2

            const clipX = 0
            const clipY = 0
            const placeX = 0
            const placeY = 0

            ctx.save()

            // 5) Move the crop origin to the canvas origin (0,0)
            ctx.translate(-cropX, -cropY)
            // 4) Move the origin to the center of the original position
            ctx.translate(centerX, centerY)
            // 3) Rotate around the origin
            ctx.rotate(rotateRads)
            // 2) Scale the image
            ctx.scale(scale, scale)
            // 1) Move the center of the image to the origin (0,0)
            ctx.translate(-centerX, -centerY)

            ctx.drawImage(
              image,
              clipX,
              clipY,
              image.naturalWidth,
              image.naturalHeight,
              placeX,
              placeY,
              image.naturalWidth,
              image.naturalHeight
            )

            ctx.restore()

            const b64 = canvas.toDataURL('image/*')
            if (props.onChange) props.onChange(b64)
          }
        }
      }
    }, drawCanvasDelay)

    return () => {
      clearTimeout(t)
    }
  }, [canvasContainer, completedCrop, props, showCanvas])

  useEffect(() => {
    setCrop(undefined)
    if (imageSrc) {
      if (typeof imageSrc === 'string') {
        setImgSrc(imageSrc)
      } else {
        const reader = new FileReader()
        const result = reader.result ? reader.result.toString() : ''
        reader.addEventListener('load', () => setImgSrc(result))
        reader.readAsDataURL(imageSrc)
      }
    }
  }, [imageSrc])

  const toggleAspectDelay = 200
  useEffect(() => {
    const t = setTimeout(() => {
      if (enableAspect && imgRef.current) {
        const { width, height } = imgRef.current
        setAspect(16 / 16)
        setCrop(centerAspectCrop(width, height, 16 / 16))
      } else {
        setAspect(undefined)
      }
    }, toggleAspectDelay)

    return () => {
      clearTimeout(t)
    }
  }, [enableAspect])

  return (
    <>
      {Boolean(imgSrc) && (
        <Stack direction="row" space="none" style={{ maxWidth: '100%' }}>
          <View
            style={{
              width: showCanvas ? '50%' : '100%',
              padding: GlobalStyles.PADDING_SIZES.xxs,
              alignItems: 'center',
            }}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              style={{ width: 'fit-content' }}>
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(1) rotate(0deg)`, width: defaultWidth }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </View>
          {showCanvas && (
            <View style={{ width: '50%', padding: GlobalStyles.PADDING_SIZES.xxs, alignItems: 'center' }}>
              {Boolean(completedCrop) && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop ? completedCrop.width : 0,
                    height: completedCrop ? completedCrop.height : 0,
                  }}
                />
              )}
            </View>
          )}
        </Stack>
      )}
    </>
  )
}

export { ImageCrop }
