import { Agreements, API, Deliverable, Events, Job, Talent } from '../types'
import { HttpClient } from './api.service'

export const jobService = {
  fetchAllJobs: (queryParams: Job.iJobListQueryParams) => fetchAllJobs(queryParams),
  fetchAddedJobs: (queryParams: Job.iJobListQueryParams) => fetchAllJobs({ ...queryParams, jobListType: Job.iJobType.ADDED }),
  fetchOwnedJobs: (queryParams: Job.iJobListQueryParams) => fetchAllJobs({ ...queryParams, jobListType: Job.iJobType.OWNED }),
  fetchManagedJobs: (queryParams: Job.iJobListQueryParams) => fetchAllJobs({ ...queryParams, jobListType: Job.iJobType.MANAGED }),
  fetchJobById: ({ jobEid }: { jobEid: string }) => {
    return HttpClient.get<string, Job.iJob, null>({
      endpoint: `${API.BookingEndpoints.BOOKING_JOBS}/${jobEid}`,
    })
  },
  updateJobById: ({ jobEid, payload }: { jobEid: string; payload: Job.iJobPayload }) => {
    return HttpClient.put<Job.iJobPayload, Job.iJob>({
      endpoint: `${API.BookingEndpoints.BOOKING_JOBS}/${jobEid}`,
      payload,
    })
  },
  create: ({ payload }: { payload: Job.iJobPayload }) => {
    return HttpClient.post<Job.iJobPayload, Job.iJob>({
      endpoint: API.BookingEndpoints.BOOKING_JOBS,
      payload,
    })
  },
  createPlus: ({ payload }: { payload: Job.iJobPlusPayload }) => {
    return HttpClient.post<Job.iJobPlusPayload, Job.iJob>({
      endpoint: API.BookingEndpoints.BOOKING_JOBS,
      payload,
    })
  },
  deleteJobById: ({ jobEid }: { jobEid: string }) => {
    return HttpClient.delete<null, null>({
      endpoint: `${API.BookingEndpoints.BOOKING_JOBS}/${jobEid}`,
      payload: null,
    })
  },
  archiveJobById: ({ jobEid }: { jobEid: string }) => {
    return HttpClient.put<null, null>({
      endpoint: `${API.BookingEndpoints.BOOKING_JOBS}/${jobEid}/archive`,
      payload: null,
    })
  },
  fetchJobEventTypes: () => {
    return HttpClient.get<null, Events.iEventTypes[]>({
      endpoint: `${API.BookingEndpoints.BOOKING_EVENTS}`,
    })
  },
  getDeliverables: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.BookingEndpoints.BOOKING_JOB_DELIVERABLES.replace(':eid', jobEid)
    return HttpClient.get<string, Deliverable.iDeliverable[], null>({
      endpoint,
    })
  },
  jobShortlistCreate: ({ jobEid, payload }: { jobEid: string; payload: Talent.iJobTalentShortlistPayload }) => {
    const endpoint = API.BookingEndpoints.SHORTLIST_SHORTLIST_TALENT.replace(':eid', jobEid)
    return HttpClient.post<Talent.iJobTalentShortlistPayload, Job.iJob>({
      endpoint: endpoint,
      payload,
    })
  },
  jobShortlistUpdate: ({ jobEid, payload }: { jobEid: string; payload: Talent.iJobTalentShortlistPayload }) => {
    const endpoint = API.BookingEndpoints.SHORTLIST_SHORTLIST_TALENT.replace(':eid', jobEid)
    return HttpClient.put<Talent.iJobTalentShortlistPayload, Job.iJob>({
      endpoint: endpoint,
      payload,
    })
  },
  publishJob: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.BookingEndpoints.BOOKING_PUBLISH.replace(':eid', jobEid)
    return HttpClient.post<null, Job.iJob>({
      endpoint: endpoint,
    })
  },
  assignTalent: ({ payload }: { payload: Job.iAssignTalentToJob }) => {
    const endpoint = API.BookingEndpoints.ASSIGN_TALENT
    return HttpClient.post<Job.iAssignTalentToJob, Agreements.iJobAgreementList>({
      endpoint,
      payload,
    })
  },
  fetchJobUsers: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.BookingEndpoints.JOB_USERS.replace(':eid', jobEid)
    return HttpClient.get<string, Job.iJobUsersResponse, null>({
      endpoint,
    })
  },
  syncJobUsers: ({ jobEid, payload }: { jobEid: string; payload: Job.iJobSyncPayload }) => {
    const endpoint = API.BookingEndpoints.SYNC_JOB_USERS.replace(':eid', jobEid)
    return HttpClient.post<Job.iJobSyncPayload, Job.iJobUsersResponse>({
      endpoint,
      payload,
    })
  },
  fetchDeliverableUsers: ({ deliverableEid }: { deliverableEid: string }) => {
    const endpoint = API.BookingEndpoints.GET_DELIVERABLE_USERS.replace(':eid', deliverableEid)
    return HttpClient.get<string, Job.iDeliverableUsersResponse, null>({
      endpoint,
    })
  },
  syncDeliverableUsers: ({ deliverableEid, payload }: { deliverableEid: string; payload: Job.iJobSyncPayload }) => {
    const endpoint = API.BookingEndpoints.SYNC_DELIVERABLE_USERS.replace(':eid', deliverableEid)
    return HttpClient.post<Job.iDeliverableSyncPayload, Job.iDeliverableUsersResponse>({
      endpoint,
      payload,
    })
  },
  leaveJob: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.BookingEndpoints.LEAVE_JOB.replace(':eid', jobEid)
    return HttpClient.post<null, null>({
      endpoint,
    })
  },
  completeJob: ({ jobEid }: { jobEid: string }) => {
    const endpoint = API.BookingEndpoints.BOOKING_COMPLETE.replace(':eid', jobEid)
    return HttpClient.put<null, Job.iJob>({
      endpoint: endpoint,
    })
  },
  createJobRequest: ({ payload }: { payload: Job.iJobExternal }) => {
    return HttpClient.post<Job.iJobExternal, Job.iJobExternal>({
      endpoint: API.BookingEndpoints.BOOKING_EXTERNAL_CREATE,
      payload,
    })
  },
  fetchJobRequestById: ({ jobRequestEid, token }: { jobRequestEid: string; token: string }) => {
    return HttpClient.get<string, Job.iJobExternal, null>({
      endpoint: API.BookingEndpoints.BOOKING_EXTERNAL.replace(':eid', jobRequestEid).replace(':token', token),
    })
  },
  acceptJobRequestById: ({ jobRequestEid, token, payload }: { jobRequestEid: string; token: string; payload: Job.iJobExternal }) => {
    return HttpClient.put<Job.iJobExternal, Job.iJobExternal>({
      endpoint: API.BookingEndpoints.BOOKING_EXTERNAL_ACCEPT.replace(':eid', jobRequestEid).replace(':token', token),
      payload,
    })
  },
  getAggregates: () => {
    return HttpClient.get<null, Job.iJobAggregates>({
      endpoint: API.BookingEndpoints.AGGREGATES,
    })
  },
  generateFileUploadUrl: ({ eid, file_names }: { eid: string; file_names: string[] }) => {
    return HttpClient.post<{ file_names: string[] }, Job.iFile[]>({
      endpoint: `${API.BookingEndpoints.GENERATE_FILE_UPLOAD_URL}`.replace(':eid', eid),
      payload: { file_names },
    })
  },
  generateFileDownloadUrl: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.post<Job.iAttachFilesPayload, Job.iFile[]>({
      endpoint: `${API.BookingEndpoints.GENERATE_FILE_DOWNLOAD_URL}`.replace(':eid', eid),
      payload,
    })
  },
  attachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Job.iJob>({
      endpoint: API.BookingEndpoints.ATTACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  detachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Job.iJob>({
      endpoint: API.BookingEndpoints.DETACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  getCampaign: (eid: string) => {
    return HttpClient.get<null, Job.iJobSocial>({
      endpoint: API.BookingEndpoints.JOB_CAMPAIGN.replace(':eid', eid),
    })
  },
  syncCampaign: (eid: string) => {
    return HttpClient.put<string, any>({
      endpoint: API.BookingEndpoints.JOB_CAMPAIGN.replace(':eid', eid),
    })
  },
  createCampaign: ({ eid, payload }: { eid: string; payload: Job.iCreateCampaignPayload }) => {
    return HttpClient.post<any, any>({
      endpoint: API.BookingEndpoints.JOB_CAMPAIGN.replace(':eid', eid),
      payload,
    })
  },
}

const fetchAllJobs = (queryParams: Job.iJobListQueryParams & { jobListType?: Job.iJobType }) => {
  let endpoint = `${API.BookingEndpoints.BOOKING_JOBS_LIST}`
  switch (queryParams.jobListType) {
    case 'added':
      endpoint = `${API.BookingEndpoints.BOOKING_JOBS_LIST_ADDED}`
      break
    case 'owned':
      endpoint = `${API.BookingEndpoints.BOOKING_JOBS_LIST_OWNED}`
      break
    case 'managed':
      endpoint = `${API.BookingEndpoints.BOOKING_JOBS_LIST_MANAGED}`
      break
    default:
      endpoint = `${API.BookingEndpoints.BOOKING_JOBS_LIST}`
      break
  }

  let query = endpoint
    .replace(':page', queryParams.page ?? '')
    .replace(':per_page', queryParams.per_page ?? '')
    .concat(queryParams.has_shortlisted ? `&has_shortlisted=${queryParams.has_shortlisted}` : '')
    .concat(queryParams.has_managed_shortlisted ? `&has_managed_shortlisted=${queryParams.has_managed_shortlisted}` : '')
    .concat(
      queryParams.deliverable_statuses
        ? queryParams.deliverable_statuses.reduce(
            (acc, status: string, index) =>
              acc.concat(status.toLowerCase() === 'all' ? '' : `&deliverable_statuses[]=${status.toUpperCase()}`),
            ''
          )
        : ''
    )
    .concat(
      queryParams.deliverable_agreements_statuses
        ? queryParams.deliverable_agreements_statuses.reduce(
            (acc, status: string, index) =>
              acc.concat(status.toLowerCase() === 'all' ? '' : `&deliverable_agreements_statuses[]=${status.toUpperCase()}`),
            ''
          )
        : ''
    )
    .concat(queryParams.talents ? queryParams.talents.reduce((acc, talents: string, index) => acc.concat(`&talents[]=${talents}`), '') : '')
    .concat(queryParams.owners ? queryParams.owners.reduce((acc, owners: string, index) => acc.concat(`&owners[]=${owners}`), '') : '')
    .concat(
      queryParams.statuses && queryParams.statuses.toString().toLowerCase() !== 'all'
        ? queryParams.is_lead
          ? `&is_lead=true`
          : `&statuses[]=${queryParams.statuses}`
        : ''
    )
    .concat(queryParams.show_aggregations_only ? `&show_aggregations_only=${queryParams.show_aggregations_only}` : '')

  return HttpClient.get<null, Array<Job.iJobList>, Job.iJobListMeta>({
    endpoint: query,
    payload: null,
  })
}
