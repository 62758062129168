import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import {
  Autocomplete,
  autocompleteClasses,
  Button,
  outlinedInputClasses,
  Stack,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Textfield } from 'src/components/form/Textfield'
import { getIcon } from './components'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Types } from '@orbit/index'
import { useAddTalentSocials, useTalentSocialCampaign } from '@utils/query/useTalentSocialCampaign'
import { FormAutoComplete } from '../form/AutoComplete'
import { DevTool } from '@hookform/devtools'
import { useContext } from 'react'
import { isEditableContext } from '../talent/form'

const PLATFORM_OPTIONS = [{ value: 'Youtube' }, { value: 'Instagram' }, { value: 'Twitter' }, { value: 'TikTok' }]

const Label = (props: TypographyProps) => (
  <Typography sx={{ fontSize: '12px', color: GlobalStyles.SLATE_700, lineHeight: '16px', letterSpacing: '-0.12px' }} {...props} />
)

const MinorValue = (props: TypographyProps) => (
  <Typography
    sx={{ fontSize: '12px', fontWeight: 500, color: GlobalStyles.MONO_BLACK, lineHeight: '16px', letterSpacing: '-0.12px' }}
    {...props}
  />
)
type tFormData = { platform: { value: string }; handle: string }

export const SocialCampaign = () => {
  const { state: isEditable, setState: handleSetIsEditable } = useContext(isEditableContext)

  const form = useForm<tFormData>({
    defaultValues: {
      platform: { value: '' },
      handle: '',
    },
  })

  const { mutate, isPending: isAddTalentPending } = useAddTalentSocials({
    onSuccess: () => {
      form.reset()
    },
  })
  const { data, isPending } = useTalentSocialCampaign()

  const _handleSubmit: SubmitHandler<tFormData> = (formData) => {
    const payload: Types.Talent.iTalentSocial = {
      network: formData.platform.value,
      handle: formData.handle,
    }

    mutate(payload)
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <form onSubmit={form.handleSubmit(_handleSubmit)}>
        <Stack paddingBottom="24px">
          <Stack
            direction="row"
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              ['>div']: {
                marginTop: '-1px',
                marginLeft: '-1px',
                border: `1px solid ${GlobalStyles.SLATE_100}`,
                boxSizing: 'border-box',
              },
              ['>div:last-child']: {
                marginRight: '-1px',
              },
            }}>
            {data &&
              data.map(({ handle, network, followers = 0, engagement = 0, likesPerPost = 0 }) => (
                <Stack key={network} minWidth="184px" flex={1} alignItems="center" rowGap="8px" padding="24px">
                  {getIcon({ platform: network })}
                  <Typography
                    sx={{
                      color: GlobalStyles.PRIMARY_500,
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '20px' /* 150% */,
                      letterSpacing: '-0.14px',
                    }}>
                    {handle}
                  </Typography>
                  <Typography
                    sx={{
                      color: GlobalStyles.MONO_BLACK,
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '24px' /* 150% */,
                      letterSpacing: '-0.16px',
                    }}>
                    {followers}
                  </Typography>
                  <Label>Followers</Label>
                  <MinorValue>{engagement}</MinorValue>
                  <Label>Engagement</Label>
                  <MinorValue>{likesPerPost}</MinorValue>
                  <Label>Likes per post</Label>
                </Stack>
              ))}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            columnGap="30px"
            rowGap="8px"
            padding="8px 24px"
            borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}
            flexWrap="wrap">
            <Stack direction="row" alignItems="center" columnGap={{ xxs: '5px', sm: '30px' }} rowGap="8px" flex={1}>
              <Typography sx={{ minWidth: 'fit-content' }}>Social Platform</Typography>
              <FormAutoComplete
                name="platform"
                size="small"
                disabled={!isEditable}
                textfieldProps={{ placeholder: 'Select Platform' }}
                sx={{
                  minWidth: { sm: '190px' },
                  maxWidth: { xxs: '90%', sm: '190px' },
                  [`&.${autocompleteClasses.root} `]: {
                    [`.${outlinedInputClasses.notchedOutline}`]: {
                      borderWidth: 0,
                    },
                    [`.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
                      borderWidth: 1,
                    },
                    [`.${autocompleteClasses.input}`]: {
                      color: GlobalStyles.PRIMARY_500,
                    },
                  },
                }}
                disableClearable
                options={PLATFORM_OPTIONS}
                getOptionLabel={(option) => option.value}
              />
            </Stack>
            <Stack direction="row" alignItems="center" columnGap={{ xxs: '5px', sm: '30px' }} rowGap="8px" flex={1}>
              <Typography>Handle</Typography>
              <Tooltip text="Add a Social Account" />
              <Textfield
                disabled={!isEditable}
                name="handle"
                sx={{ width: { xxs: '100%', md: '300px' } }}
                registerOptions={{ required: false }}
              />
              <Button
                variant="outlined"
                startIcon={<Icon.Plus />}
                sx={{ minWidth: '80px' }}
                type="submit"
                disabled={isPending || isAddTalentPending || !isEditable}
                onClick={form.handleSubmit(_handleSubmit)}
                aria-label="Add Social Platform">
                Add
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
