import { Orbit, Types } from '@orbit'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'

export const useJobSocialCampaign = () => {
  const router = useRouter()

  const { workspace } = useAppSelector(selectWorkspace)

  const jobEid = useMemo(() => router.query.requestId || router.query.jobEid, [router.query])

  const queryClient = useQueryClient()
  const job = queryClient.getQueryData<Types.Job.iJob>(['jobDetails', workspace, jobEid])

  return useQuery<Types.Job.iJobSocial>({
    queryKey: ['job-social-campaign', jobEid],
    queryFn: async () => {
      const res = await Orbit.Services.jobService.getCampaign(jobEid as string)
      return res.data
    },
    enabled: !!jobEid && job?.has_social_campaign,
  })
}

export const useSyncJobSocialCampaign = () => {
  const router = useRouter()

  const jobEid = useMemo(() => router.query.requestId || router.query.jobEid, [router.query])

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['sync-job-social-campaign', jobEid],
    mutationFn: async () => {
      const res = await Orbit.Services.jobService.syncCampaign(jobEid as string)
      return
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['job-social-campaign', jobEid as string] })
    },
    onError(e) {
      // @ts-expect-error
      toast.error(e?.response?.data?.message || 'Error Creating Job Campaign.')
    },
  })
}

export const useCreateJobSocialCampaign = () => {
  const router = useRouter()

  const jobEid = useMemo(() => router.query.requestId || router.query.jobEid, [router.query])

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['create-job-social-campaign', jobEid],
    mutationFn: async (payload: Pick<Types.Job.iCreateCampaignPayload, 'hashtags' | 'requirements'>) => {
      const res = await Orbit.Services.jobService.createCampaign({
        eid: jobEid as string,
        payload: {
          start_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          end_datetime: moment(new Date()).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          ...payload,
        },
      })
      return res.data
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['job-social-campaign', jobEid as string] })
      toast.update('jobCampaign', { render: 'Successfully created campaign', type: 'success', autoClose: 5000, isLoading: false })
    },
    onMutate() {
      toast.loading('Creating Job Campaign...', { toastId: 'jobCampaign' })
    },
    onError(e) {
      toast.update('jobCampaign', {
        // @ts-expect-error
        render: e?.response?.data?.message || 'Error Creating Job Campaign.',
        type: 'error',
        autoClose: 5000,
        isLoading: false,
      })
    },
  })
}
