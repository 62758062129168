import Image from 'next/image'
import { forwardRef, memo, useState } from 'react'
import { Pressable, View } from 'react-native'
import { Icon } from '../Icon'
import { AvatarSizes, AvatarSizeStyles, AvatarStyles } from './styles'
import { AvatarDefaults, AvatarProps } from './types'

const Avatar = (
  { style, leftComponent, rightComponent, type = AvatarDefaults.TYPE, size = AvatarDefaults.SIZE, placeHolder, ...props }: AvatarProps,
  ref?: any
) => {
  const [loadingFailed, setOnLoadingFailed] = useState(false)
  const borderRadius = AvatarSizes[size] / 2

  const _handleOnPress = () => {
    props.onPress && props.onPress(props.data)
  }
  /**
   * Using next/image if available so that we can use nextJs image optimisations on sauron
   * For storybook purposes, if nextjs is not available, use the image component from react-native
   * @returns Image component
   */
  let ImageComponent = () => {
    return (
      <View style={[AvatarSizeStyles[size], { borderRadius: borderRadius }, props.imageStyle]}>
        <Image
          onError={(e: any) => {
            setOnLoadingFailed(true)
            console.error('Error Loading Avatar', e)
          }}
          fill={true}
          style={{ borderRadius: '100%', objectFit: 'contain' }}
          src={props.image ?? ''}
          alt={''}
        />
      </View>
    )
  }

  const _renderImage = () => {
    if (loadingFailed) {
      return (
        placeHolder || (
          <View style={[AvatarSizeStyles[size], { borderRadius, alignItems: 'center', justifyContent: 'center' }, props.imageStyle]}>
            <Icon.User />
          </View>
        )
      )
    } else if (props.image) {
      return <ImageComponent />
    } else if (placeHolder) {
      return placeHolder
    } else {
      return <></>
    }
  }

  return (
    <View
      style={[AvatarStyles[type], AvatarSizeStyles[size], { borderRadius, alignItems: 'center', justifyContent: 'center' }, style]}
      ref={ref}
      {...props}>
      <Pressable onPress={_handleOnPress} disabled={!props.isPressable}>
        {leftComponent ?? null}
        {_renderImage()}
        {rightComponent ?? null}
      </Pressable>
    </View>
  )
}

export default memo(forwardRef(Avatar))
