import { applyResponsiveProp, GlobalStyles, Icon, StackV2, useScreenDimensions } from '@andromeda'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { AppStyles } from '@styles'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext, PathEnums } from '@utils/context/Navigation.context'
import { DirectoryPermissions, ImportPermissions, JobPermission } from '@utils/enum/permissions'
import { hasPermission } from '@utils/functions/permission'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo } from 'react'
import { PermissionWrapper } from '../hocs'
import { DrawerContext, DrawerProvider } from './DrawerContext'
import { InnerDrawerMenuItem } from './MenuItem'
import { WorkspacesDrawer } from './WorkspacesDrawer'
import { useInstalled } from '@utils/hooks/useInstalled'
import { useMenu } from '@utils/hooks/useMenu'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { Menu, MenuItem, Stack, menuItemClasses, paperClasses } from '@mui/material'

const Drawer = () => {
  const { goToClientRequestForm, pathEnums, workspace } = useContext(NavigationContext)
  const router = useRouter()
  const { toggleModal } = useContext(ModalContext)
  const { permissions } = useAppSelector(selectAuth)
  const { viewedJob } = useAppSelector(selectJobs)

  const { installed } = useInstalled()
  const drawerMenu = useMenu()

  const { features } = useContext(FeatureFlagsContext)
  const { open, toggleWorkspaceDrawer: toggleOpen } = useContext(DrawerContext)

  const { size: screenSize } = useScreenDimensions()

  const canCreateJob = useMemo(() => {
    return hasPermission({
      allowedPermissions: [JobPermission.CREATE],
    })
  }, [permissions])

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'Semicolon') {
        // eslint-disable-next-line no-console
        console.log('Permissions: ', permissions)
        // eslint-disable-next-line no-console
        if (viewedJob) console.log('Viewed Job Permissions: ', viewedJob.permissions)
      }
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyJ') {
        event.preventDefault()
        if (canCreateJob) {
          // eslint-disable-next-line no-console
          console.log('Opening new request modal')
          toggleModal(ModalPages.ADD_JOB)
        } else {
          // eslint-disable-next-line no-console
          console.log("Ain't got no create permissions")
        }
      }
    }
    document.addEventListener('keydown', callback)
    return () => {
      document.removeEventListener('keydown', callback)
    }
  })

  return (
    <StackV2
      role="menu"
      id="workspace-menu"
      maxHeight={`${window.innerHeight}px`}
      alignItems={'center'}
      direction={{ xxs: 'row', sm: 'column' }}
      justifyContent={{ xxs: 'space-between', sm: 'flex-start' }}
      width={{ xxs: '100%', sm: AppStyles.MENU_ITEM_HEIGHT.sm }}
      paddingX={{ xxs: '6px', sm: 0 }}
      boxSizing="border-box"
      minHeight={{ sm: `${window.innerHeight}px` }}
      style={{
        backgroundColor: GlobalStyles.SLATE_800,
      }}>
      {!open && (
        <Box display={{ xxs: 'flex', md: 'none' }}>
          <IconButton
            sx={{
              height: AppStyles.MENU_ITEM_HEIGHT,
              width: AppStyles.MENU_ITEM_HEIGHT,
              padding: 0,
              borderRadius: 0,
            }}
            onClick={() => toggleOpen && toggleOpen()}>
            {<Icon.Menu color="white" size={applyResponsiveProp({ xs: 28, md: 24, default: 24 }, screenSize)} />}
          </IconButton>
        </Box>
      )}
      <Link
        role="menuitem"
        aria-label="Dashboard"
        href={{
          pathname: pathEnums.DASHBOARD,
          query: { workspace },
        }}>
        <InnerDrawerMenuItem
          title="Dashboard"
          action={() => {
            // goToDashboard()
          }}
          selected={router.route === pathEnums.DASHBOARD}
          icon={<Icon.Home color={GlobalStyles.MONO_WHITE} size={applyResponsiveProp({ xs: 28, md: 24, default: 24 }, screenSize)} />}
        />
      </Link>
      <PermissionWrapper permissions={permissions} allowedPermissions={[JobPermission.CREATE]}>
        <Box display={{ xxs: 'flex', sm: 'none' }}>
          <InnerDrawerMenuItem
            title="Add Job"
            action={() => {
              router.push({ pathname: pathEnums.REQUESTS, query: { workspace, status: 'ALL', page: '1', per_page: '20', create: true } })
            }}
            selected={false}
            icon={
              <Icon.PlusCircle color={GlobalStyles.MONO_WHITE} size={applyResponsiveProp({ xs: 28, md: 24, default: 24 }, screenSize)} />
            }
          />
        </Box>
      </PermissionWrapper>
      <Link
        role="menuitem"
        aria-label="jobs"
        href={{
          pathname: pathEnums.REQUESTS,
          query: { workspace, status: 'ALL', page: '1', per_page: '20' },
        }}>
        <InnerDrawerMenuItem
          title="Jobs"
          selected={router.pathname.includes(pathEnums.REQUESTS)}
          icon={<Icon.Layers color={GlobalStyles.MONO_WHITE} size={applyResponsiveProp({ xs: 28, md: 24, default: 24 }, screenSize)} />}
        />
      </Link>
      {/* <Link > */}
      {/* </Link> */}
      {/* Removing Client Request Form in the Nav. Commenting it instead of deleting the code just in case we decided to put it back */}
      {/* <PermissionWrapper permissions={permissions} allowedPermissions={[JobPermission.CREATE_EXTERNAL]}>
        <Link
          role="menuitem"
          aria-label="Client Request Form"
          href={{
            pathname: pathEnums.CLIENT_REQUEST_FORM,
            query: { workspace },
          }}>
          <InnerDrawerMenuItem
            title="Client Request Form"
            selected={router.route.includes(pathEnums.CLIENT_REQUEST_FORM)}
            icon={<Icon.FileText color={GlobalStyles.MONO_WHITE} />}
          />
        </Link>
      </PermissionWrapper> */}
      <Menu
        anchorEl={drawerMenu.anchorEl}
        open={drawerMenu.open}
        onClose={drawerMenu.handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          [`.${paperClasses.root}`]: {
            backgroundColor: GlobalStyles.SLATE_900,
            [`.${menuItemClasses.root}`]: {
              color: 'white',
            },
          },
          a: { textDecoration: 'none' },
        }}>
        {/* <PermissionWrapper permissions={permissions} allowedPermissions={[ImportPermissions.LIST]}>
          <Link
            role="menuitem"
            aria-label="Import Users"
            href={{
              pathname: PathEnums.IMPORT_SECTION,
              query: {
                workspace,
                section: 'users',
              },
            }}>
            <MenuItem>Import Users</MenuItem>
          </Link>
          <Link
            role="menuitem"
            aria-label="Import Talent"
            href={{
              pathname: PathEnums.IMPORT_SECTION,
              query: {
                workspace,
                section: 'talents',
              },
            }}>
            <MenuItem>Import Talent</MenuItem>
          </Link>
        </PermissionWrapper> */}
        <PermissionWrapper permissions={permissions} allowedPermissions={Object.values(DirectoryPermissions)}>
          <Link
            onClick={drawerMenu.handleClose}
            role="menuitem"
            aria-label="Departments"
            href={{
              pathname: `${pathEnums.DIRECTORY}/departments`,
              query: { workspace },
            }}>
            <MenuItem selected={router.route === `${pathEnums.DIRECTORY}/departments`}>Departments</MenuItem>
          </Link>
          <Link
            onClick={drawerMenu.handleClose}
            role="menuitem"
            aria-label="Organisations"
            href={{
              pathname: `${pathEnums.DIRECTORY}/organisations`,
              query: { workspace },
            }}>
            <MenuItem selected={router.route === `${pathEnums.DIRECTORY}/organisations`}>Organisations</MenuItem>
          </Link>
          <Link
            onClick={drawerMenu.handleClose}
            role="menuitem"
            aria-label="Talent Profiles"
            href={{
              pathname: `${pathEnums.DIRECTORY}/talent-profiles`,
              query: { workspace },
            }}>
            <MenuItem selected={router.route === `${pathEnums.DIRECTORY}/talent-profiles`}>Talent Profiles</MenuItem>
          </Link>
          <Link
            onClick={drawerMenu.handleClose}
            role="menuitem"
            aria-label="Users"
            href={{
              pathname: `${pathEnums.DIRECTORY}/users`,
              query: { workspace },
            }}>
            <MenuItem selected={router.route === `${pathEnums.DIRECTORY}/users`}>Users</MenuItem>
          </Link>
        </PermissionWrapper>
      </Menu>
      <PermissionWrapper permissions={permissions} allowedPermissions={[...Object.values(DirectoryPermissions), ImportPermissions.LIST]}>
        <Box onClick={drawerMenu.handleClick} sx={{ padding: 0, display: { xxs: 'flex', sm: 'none' } }}>
          <InnerDrawerMenuItem
            selected={router.route.includes(pathEnums.DIRECTORY)}
            title="Directory"
            buttonStyleOverride={{ width: '45px' }}
            icon={
              <Stack direction="row" alignItems="center" marginLeft="5px">
                <Icon.Users color={GlobalStyles.MONO_WHITE} size={28} />
                <Stack>
                  <Icon.ChevronUp size={12} color={GlobalStyles.SLATE_300} />
                  <Icon.ChevronDown size={12} color={GlobalStyles.SLATE_300} />
                </Stack>
              </Stack>
            }
          />
        </Box>
      </PermissionWrapper>
      <Box display={{ xxs: 'none', sm: 'block' }}>
        <PermissionWrapper permissions={permissions} allowedPermissions={Object.values(DirectoryPermissions)}>
          <Link
            role="menuitem"
            aria-label="Directory"
            href={{
              pathname: pathEnums.DIRECTORY,
              query: { workspace },
            }}>
            <InnerDrawerMenuItem
              title="Directory"
              selected={router.route.includes(pathEnums.DIRECTORY)}
              icon={<Icon.Users color={GlobalStyles.MONO_WHITE} size={applyResponsiveProp({ xs: 28, md: 24, default: 24 }, screenSize)} />}
            />
          </Link>
        </PermissionWrapper>
      </Box>
      <Box display={{ xxs: 'none', sm: 'block' }}>
        <PermissionWrapper permissions={permissions} allowedPermissions={[ImportPermissions.LIST]}>
          {features.isImportUserEnabled ? (
            <Link
              role="menuitem"
              aria-label="Import"
              href={{
                pathname: pathEnums.IMPORT,
                query: { workspace },
              }}>
              <InnerDrawerMenuItem
                title="Import"
                selected={router.route.includes(pathEnums.IMPORT)}
                icon={<Icon.Upload color={GlobalStyles.MONO_WHITE} />}
              />
            </Link>
          ) : (
            <></>
          )}
        </PermissionWrapper>
      </Box>
    </StackV2>
  )
}

export { Drawer, WorkspacesDrawer, DrawerContext, DrawerProvider }
