import { useMemo, useState } from 'react'
import { Types } from '@orbit'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import useDebounce from '@utils/hooks/useDebounce'
import { useRouter } from 'next/router'

type SearchKeyword = string | undefined

type UseDirectoryQuery<FetchFn = Function> = {
  key: string | string[]
  dataFetchFn: FetchFn
  per_page?: number
  context?: string
  enabled?: boolean
}

type ResultsMeta = Types.Meta.iMeta & { pagination?: Types.Meta.iPagination }

export default function useDirectoryQuery<T>({ key, dataFetchFn, context, per_page, enabled = true }: UseDirectoryQuery) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { workspace } = useAppSelector(selectWorkspace)
  const [queryParams, setQueryParams] = useState<{ query: SearchKeyword; page: number }>({
    query: undefined,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
  })

  const debouncedQueryParams = useDebounce(queryParams, 500)
  const _queryKey = [...(Array.isArray(key) ? key : [key]), workspace, debouncedQueryParams]

  const { data, isLoading, error, status, refetch } = useQuery<{ data: T; meta: ResultsMeta }>({
    queryKey: _queryKey,
    queryFn: async ({ queryKey }) => {
      try {
        const { query, page, sort_by, sort_order, ...extraParams } = queryKey[queryKey.length - 1] as {
          query: SearchKeyword
          page: number
          sort_by: string
          sort_order: string
        }

        const newParams: Record<string, string | number | Array<string | number>> = {
          page,
          per_page: per_page ?? 20,
          ...extraParams,
        }

        if (query) {
          newParams['query'] = typeof query === 'string' ? [query] : query
        }

        if (sort_by) {
          newParams['sort_by'] = sort_by
        }

        if (sort_order) {
          newParams['sort_order'] = sort_order
        }

        if (context && debouncedQueryParams.query?.length) {
          trackEvent({
            event: TrackingEventEnums.Others.DIRECTORY_SEARCH,
            eventProperties: { workspace, context, searchKeyword: debouncedQueryParams.query },
          })
        }

        return await dataFetchFn(newParams)
      } catch (error) {
        console.error('useDirectoryQuery: ', error)
      }
    },
    enabled: !!key && !!dataFetchFn && !!workspace && enabled,
    placeholderData: keepPreviousData,
  })

  const paginationData = useMemo(() => {
    const listMeta = data?.meta

    if (listMeta) {
      let currentPage = listMeta.current_page || 0
      let totalPage = listMeta.last_page
      let nextPage = currentPage + 1 > totalPage ? 0 : currentPage + 1
      let previousPage = currentPage - 1 <= 0 ? 0 : currentPage - 1

      let totalResult = listMeta.total || 0

      if (listMeta.pagination) {
        const pagination = listMeta.pagination as Types.Meta.iPagination
        currentPage = pagination.current_page
        totalPage = pagination.total_number_of_pages
        nextPage = pagination.next_page
        totalResult = pagination.total_results
        previousPage = currentPage - 1
      }

      return {
        currentPage,
        totalPage,
        nextPage,
        previousPage,
        totalResult,
      }
    }
  }, [data?.meta])

  const updateQueryData = <U>(newData: U & { eid: string }) => {
    queryClient.setQueryData(_queryKey, (prevData: typeof data) => {
      if (prevData && Array.isArray(prevData.data)) {
        return {
          ...prevData,
          data: prevData.data.map((item) => (item.eid === newData.eid ? newData : item)) as T,
        }
      }
      return prevData
    })
  }

  return {
    data: data?.data,
    listMeta: data?.meta,
    isLoading,
    error,
    status,
    paginationData,
    queryParams,
    setQueryParams,
    updateQueryData,
    refetch,
  }
}
