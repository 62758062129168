import { Types } from '@orbit/index'
import { iSearchFilters } from 'src/components/SearchFilter'

export default function (customField: Types.Workspaces.iCustomField, currentFilters: iSearchFilters[] = []) {
  const customFieldOptions = [{ label: 'All', value: 'all' }]

  if (customField.list_options) {
    customFieldOptions.push(
      ...(customField.list_options?.split(',').map((o) => {
        const strippedValue = o.trim()
        return { label: strippedValue, value: strippedValue }
      }) || [])
    )
  }

  return [
    ...currentFilters,
    {
      key: customField.key,
      label: customField.display_name,
      options: customFieldOptions,
      isCustomField: true,
    },
  ]
}
