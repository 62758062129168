import { HttpClient } from './api.service'
import { Mention } from '../types/mention'
import { API } from '../types'

export const mentionService = {
  get: (eid: string) => {
    return HttpClient.get<null, { users: Mention.iMention[]; talents: Mention.iMention[] }>({
      endpoint: API.MentionEndpoints.GET.replace(':eid', eid),
      payload: null,
    })
  },
}
