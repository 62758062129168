import { forwardRef, memo, useEffect, useState } from 'react'
import { FlatList as NativeList, Text, TouchableOpacity as NativeTouchableOpacity } from 'react-native'
import { isWeb } from '../helpers'
import { View } from '../View'
import { ListHoverStyles, ListHoverTextStyles, ListStyles, ListTextStyles } from './styles'
import { ListDefaults, ListGroupProps } from './types'
import { GlobalStyles } from '../globalStyles'

const ListGroup = ({ style, textStyle, type = ListDefaults.TYPE, ...props }: ListGroupProps, ref: any) => {
  const [hovered, setHovered] = useState<any | null>(null)
  const [disabledItem, setDisabledItem] = useState<any>(undefined) // set to any because type is unpredictable

  useEffect(() => {
    setDisabledItem(props.disabledItem)
  }, [props.disabledItem])

  const RenderList = ({ item }: any) => {
    if (disabledItem && (disabledItem.id || disabledItem.eid)) {
      if (
        (disabledItem?.id && item.id.toString() === disabledItem.id.toString()) ||
        (disabledItem?.eid && item.eid.toString() === disabledItem.eid.toString())
      ) {
        return (
          <View style={props.disabledItemStyle}>
            <ListItem item={item} disabled={true} />
          </View>
        )
      }
    }

    return (
      <NativeTouchableOpacity onPress={() => props.onItemPress(item)}>
        <ListItem item={item} disabled={false} />
      </NativeTouchableOpacity>
    )
  }

  const ListItem = ({ item, disabled }: { item: any; disabled: boolean }) => {
    return (
      <View
        role="menuitem"
        onMouseEnter={() => (props.hover && isWeb() && !disabled ? setHovered(item) : null)}
        onMouseLeave={() => (props.hover && isWeb() && !disabled ? setHovered(null) : null)}
        style={[ListStyles[type], style, item === hovered && (props.listHoverStyle || ListHoverStyles[type])]}>
        {!props.renderItem ? (
          <Text
            style={[
              ListTextStyles[type],
              textStyle,
              item === hovered && (props.listTextHoverStyle || ListHoverTextStyles[type]),
              disabled && { color: GlobalStyles.SLATE_200 },
            ]}>
            {props.valuesLabel ? item[props.valuesLabel] : item.value}
          </Text>
        ) : (
          props.renderItem(item, item === hovered)
        )}
      </View>
    )
  }

  return (
    <View style={ListStyles.container}>
      <NativeList data={props.values} renderItem={RenderList} keyExtractor={(item) => item.eid || item.id} />
      {props.values && props.values.length == 0 && (
        <Text style={{ paddingLeft: GlobalStyles.PADDING, paddingVertical: GlobalStyles.PADDING }}>No Data Available</Text>
      )}
      {props.actionComponent}
    </View>
  )
}

export default memo(forwardRef(ListGroup))
