import { forwardRef, useState, useCallback, memo } from 'react'
import { TextInput as NativeTextInput, StyleSheet } from 'react-native'
import { InputDefaults, InputProps } from './types'
import { InputStyles } from './styles'
import { TextSizeStyles } from '../Text/styles'
import { Stack } from '../Stack'
import { TextDefaults } from '../Text/types'
import { GlobalStyles } from '../globalStyles'
import { Text } from '../Text'

const InputMain = (
  {
    style,
    labelStyle,
    errorMessage,
    type = InputDefaults.TYPE,
    size = TextDefaults.SIZE,
    placeholderTextColor = GlobalStyles.SLATE_200,
    ...props
  }: InputProps,
  ref?: any
) => {
  const [isShadowed, setIsShadowed] = useState<boolean>(false)

  const _handleOnFocus = useCallback(
    (e: any) => {
      if (props.onFocus) props.onFocus(e)
      if (type === 'shadow') setIsShadowed(true)
    },
    [props.name, type, props.onFocus]
  )

  const _handleOnBlur = useCallback(
    (e: any) => {
      if (props.onBlur) props.onBlur(e)
      if (type === 'shadow') setIsShadowed(false)
    },
    [props.name, type, props.onBlur]
  )

  const wrapperStyles = StyleSheet.flatten([props.wrapperStyle])

  return (
    <Stack
      space="none"
      align={'flex-start'}
      flex={1}
      height={'auto'}
      direction={'column'}
      style={[{ width: wrapperStyles?.width || '100%' }, props.mainWrapperStyle]}>
      <Stack
        direction="row"
        space={props.space}
        style={[InputStyles.container, isShadowed && GlobalStyles.INPUT_SHADOWED, props.wrapperStyle]}>
        {type === InputDefaults.SECONDARY ? <Text style={[InputStyles.label, labelStyle]}>{props.label}</Text> : null}
        {props.leftElement ?? null}
        <NativeTextInput
          ref={ref}
          style={[
            InputStyles[type],
            TextSizeStyles[size],
            style,
            props.disabled ? InputStyles.disabled : null,
            errorMessage ? { borderColor: 'red' } : null,
          ]}
          placeholderTextColor={placeholderTextColor}
          onFocus={_handleOnFocus}
          onBlur={_handleOnBlur}
          // @ts-expect-error
          role={'textbox'}
          accessibilityLabel={props['aria-label'] || props.label || props.placeholder}
          {...props}
        />
        {props.rightElement ?? null}
      </Stack>
      {errorMessage ? (
        <Text size={'sm'} style={[{ color: 'red' }, props.errorMessageStyle]}>
          {errorMessage}
        </Text>
      ) : null}
    </Stack>
  )
}

export default memo(forwardRef(InputMain))
