import { API, Job, Talent, User } from '../types'
import { HttpClient } from './api.service'

export const talentService = {
  list: (queryParams: Talent.iTalentListParams, customWorkspace?: string) => {
    const { job, page, per_page, query, ...extraParams } = queryParams

    return HttpClient.get<null, Array<Talent.iTalent>, Talent.iTalentListMeta>({
      endpoint: `${API.TalentEndpoints.TALENT_LIST}`
        .replace(':job', job ?? '')
        .replace(':page', page)
        .concat(
          query
            ? query.reduce((acc, keyword: string, index) => acc.concat(keyword.toLowerCase() === 'all' ? '' : `&query[]=${keyword}`), '')
            : ''
        )
        .replace(':per_page', per_page ?? ''),
      params: extraParams,
      customWorkspace,
    })
  },
  listManaged: (queryParams: Talent.iTalentListParams, customWorkspace?: string) => {
    const { job, page, per_page, query, ...extraParams } = queryParams

    return HttpClient.get<null, Array<Talent.iTalent>, Talent.iTalentListMeta>({
      endpoint: `${API.TalentEndpoints.TALENT_LIST_MANAGED}`
        .replace(':job', job ?? '')
        .replace(':page', page)
        .concat(
          query
            ? query.reduce((acc, keyword: string, index) => acc.concat(keyword.toLowerCase() === 'all' ? '' : `&query[]=${keyword}`), '')
            : ''
        )
        .replace(':per_page', per_page ?? ''),
      params: extraParams,
      customWorkspace,
    })
  },
  create: (payload: Talent.iTalentCreatePayload) => {
    return HttpClient.post<Talent.iTalentCreatePayload, Talent.iTalent>({
      payload,
      endpoint: API.TalentEndpoints.TALENT,
    })
  },
  update: ({ payload, eid }: { payload: Talent.iTalentCreatePayload; eid: string }) => {
    const endpoint = API.TalentEndpoints.TALENT_BY_EID.replace(':talentEid', eid)

    return HttpClient.put<Talent.iTalentCreatePayload, Talent.iTalent>({
      payload,
      endpoint,
    })
  },
  fetchTalentById: (talentEid: string) => {
    const endpoint = API.TalentEndpoints.TALENT_BY_EID.replace(':talentEid', talentEid)

    return HttpClient.get<string, Talent.iTalent>({
      endpoint,
    })
  },
  updateManagement: ({ eid, payload }: { eid: string; payload: Talent.iTalentManagementPayload }) => {
    const endpoint = API.TalentEndpoints.TALENT_MANAGEMENT.replace(':eid', eid)

    return HttpClient.put<Talent.iTalentManagementPayload, Talent.iTalent>({
      payload,
      endpoint,
    })
  },
  generateFileUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.TalentEndpoints.GENERATE_FILE_UPLOAD_URL}`.replace(':file_name', file_name),
    })
  },
  generateProfileImageUploadUrl: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.TalentEndpoints.GENERATE_PROFILE_IMAGE_UPLOAD_URL}`.replace(':file_name', file_name),
    })
  },
  generateProfileImageUploadUrlByEid: ({ file_name }: { file_name: string }) => {
    return HttpClient.post<null, User.iSignedImageUploadURLResponse>({
      endpoint: `${API.TalentEndpoints.GENERATE_PROFILE_IMAGE_UPLOAD_URL_BY_ID}`.replace(':file_name', file_name),
    })
  },
  listTalentJobs: (talentId: string, queryParams: Talent.iTalentJobsParams) => {
    return HttpClient.get<null, Array<Job.iJobList>>({
      endpoint: API.TalentEndpoints.LIST_TALENT_JOBS.replace(':talentid', talentId)
        .replace(':page', queryParams.page || '')
        .replace(':per_page', (queryParams.per_page ?? '').toString())
        .concat(queryParams.sort_by ? `&sort_by=${queryParams.sort_by}` : '')
        .concat(queryParams.sort_order ? `&sort_order=${queryParams.sort_order}` : ''),
    })
  },
  generateTalentFileUploadUrl: ({ eid, file_names }: { eid: string; file_names: string[] }) => {
    return HttpClient.post<{ file_names: string[] }, Job.iFile[]>({
      endpoint: `${API.TalentEndpoints.GENERATE_TALENT_FILE_UPLOAD_URL}`.replace(':eid', eid),
      payload: { file_names },
    })
  },
  generateFileDownloadUrl: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.post<Job.iAttachFilesPayload, Job.iFile[]>({
      endpoint: `${API.TalentEndpoints.GENERATE_TALENT_FILE_DOWNLOAD_URL}`.replace(':eid', eid),
      payload,
    })
  },
  attachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Job.iJob>({
      endpoint: API.TalentEndpoints.ATTACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  detachFiles: ({ eid, payload }: { eid: string; payload: Job.iAttachFilesPayload }) => {
    return HttpClient.put<Job.iAttachFilesPayload, Job.iJob>({
      endpoint: API.TalentEndpoints.DETACH_FILES.replace(':eid', eid),
      payload,
    })
  },
  getSocials: (eid: string) => {
    return HttpClient.get<null, any>({
      endpoint: API.TalentEndpoints.SOCIALS.replace(':eid', eid),
    })
  },
  syncSocials: ({ eid, payload }: { eid: string; payload: Talent.iTalentSocialPayload }) => {
    return HttpClient.put<Talent.iTalentSocialPayload, any>({
      endpoint: API.TalentEndpoints.SOCIALS.replace(':eid', eid),
      payload: { socials: payload.socials },
    })
  },
  addSocials: ({ eid, payload }: { eid: string; payload: Talent.iTalentSocial }) => {
    return HttpClient.post<Talent.iTalentSocial, any>({
      endpoint: API.TalentEndpoints.ADD_SOCIALS.replace(':eid', eid),
      payload,
    })
  },
}
