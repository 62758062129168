import { Orbit } from '@orbit'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export const useEventTypesQuery = () => {
  const router = useRouter()

  return useQuery({
    queryKey: ['event-types', router.query.workspace],
    queryFn: async () => {
      const res = await Orbit.Services.jobService.fetchJobEventTypes()
      return res.data
    },
    enabled: !!router.query.workspace,
  })
}
