import { Avatar, DropDownItemV2, DropdownV2, GlobalStyles, Icon, Label, Loading, SearchInput, View } from '@andromeda'
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Orbit, Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { setUser, setUsers } from '@redux/reducers/usersReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { PathEnums } from '@utils/context/Navigation.context'
import { ImportPermissions, InvitePermission } from '@utils/enum/permissions'
import { capitalizeFirstLetter, getInitials } from '@utils/functions/helperFunctions'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { breakpoints } from 'src/andromeda/helpers/theme'
import { DirectoryContainer, DirectoryHeader, TABLE_HEADER_STYLES } from 'src/components/directory'
import { PermissionWrapper } from 'src/components/hocs'
import { OVERFLOW_TEXT } from 'src/components/job-card/job-card'
import DirectoryLayout from 'src/components/layout/Directory.layout'
import useDirectoryQuery from '@utils/hooks/useDirectoryQuery'
import { useWorkspace } from '@utils/query/useWorkspace'

export const MenuComponent = ({ onClick }: { onClick?: () => void }) => (
  <DropdownV2
    wrapperStyle={{ width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
    menuIcon={
      <Button variant="outlined" sx={{ padding: 0, height: '40px', minWidth: '40px', borderColor: GlobalStyles.SLATE_100 }}>
        <Icon.MoreVertical color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />
      </Button>
    }>
    <DropDownItemV2 onClick={() => onClick && onClick()}>View/Edit</DropDownItemV2>
    <DropDownItemV2 onClick={() => {}}>
      <Typography color={GlobalStyles.STATUS_NEGATIVE}>Remove</Typography>
    </DropDownItemV2>
  </DropdownV2>
)

export default function UserDirectory<NextPage>() {
  const router = useRouter()

  const { toggleModal, closeModal } = useContext(ModalContext)
  const { features } = useContext(FeatureFlagsContext)
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)

  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)
  const { permissions } = useAppSelector(selectAuth)

  const {
    data: usersList,
    isLoading: isUserListLoads,
    paginationData,
    queryParams,
    setQueryParams,
    updateQueryData,
  } = useDirectoryQuery<Types.User.iUser[]>({
    key: 'listUser',
    dataFetchFn: Orbit.Services.userService.list,
    context: 'user',
  })

  const [screen, setScreen] = useState<'users' | 'import'>('users')

  const { activeWorkspace } = useWorkspace()

  const _renderEndAdornment = () => {
    if (queryParams.query)
      return (
        <IconButton onClick={() => setQueryParams({ query: undefined, page: 1 })}>
          <Icon.XCircle color={GlobalStyles.SLATE_400} />
        </IconButton>
      )
    else return <></>
  }

  useEffect(() => {
    dispatch(setUsers(usersList ?? []))
  }, [dispatch, usersList])

  return (
    <DirectoryContainer>
      <DirectoryHeader
        IconComponent={(props) => <Icon.Users {...props} />}
        title="USERS"
        createButtonLabel="Invite User"
        permissions={[InvitePermission.CREATE]}
        subHeader={`There are ${paginationData?.totalResult ?? 0} Users in the ${activeWorkspace?.name} Workspace`}
        onClick={() => {
          trackEvent({
            event: TrackingEventEnums.Invite.INVITE_STARTED,
            eventProperties: { workspace, context: 'users' },
          })
          toggleModal(ModalPages.INVITE_USER)
        }}
        searchComponent={
          <Box justifyContent={'flex-end'} color={GlobalStyles.SLATE_500} width="300px">
            <SearchInput
              value={queryParams.query ?? ''}
              endAdornment={_renderEndAdornment()}
              onChange={(event) => setQueryParams({ query: event.target.value, page: 1 })}
              placeholder="Search for Users"
              // @ts-expect-error
              borderColor={GlobalStyles.SLATE_500}
            />
          </Box>
        }
        additionalButton={
          features.isImportUserEnabled ? (
            <PermissionWrapper permissions={permissions} allowedPermissions={[ImportPermissions.USER_INVITE]}>
              <Link
                role="menuitem"
                aria-label="Import"
                href={{
                  pathname: PathEnums.IMPORT_SECTION,
                  query: { workspace, section: 'users' },
                }}>
                <Button variant="text">
                  <Stack display={{ xxs: 'none', sm: 'flex' }} direction="row" columnGap="8px" alignItems="center">
                    <Icon.Upload size={16} />
                    <Typography>Import Users</Typography>
                  </Stack>
                  <Stack display={{ xxs: 'flex', sm: 'none' }}>
                    <Icon.Upload size={16} />
                  </Stack>
                </Button>
              </Link>
            </PermissionWrapper>
          ) : (
            <></>
          )
        }
      />
      <Stack direction={'column'} width={'100%'}>
        {isUserListLoads ? (
          <View style={{ height: '50vh', justifyContent: 'center' }}>
            <Loading />
          </View>
        ) : isMobile ? (
          <Stack rowGap="10px" margin="5px">
            {usersList?.map((user: Types.User.iUser & { profile_image: Types.User.iProfileImage | string | null }, i) => {
              return (
                <Stack key={user.eid} direction="row" className="mobile-directory-paper">
                  <Stack maxWidth="50%">
                    <Typography fontSize="14px" color={GlobalStyles.MONO_BLACK} fontWeight={500} {...OVERFLOW_TEXT}>
                      {user.name}
                    </Typography>
                    <Typography variant={'caption'} fontSize="14px" color={GlobalStyles.SLATE_700} {...OVERFLOW_TEXT}>
                      {user.roles ? `${user.roles.map((role) => role.label)}` : '-'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" columnGap="12px">
                    <Stack style={{ minHeight: '40px' }} className="justify-center">
                      <Label
                        type={'APPLIED'}
                        text={capitalizeFirstLetter('active')}
                        size={'base'}
                        textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY }}
                        style={{ width: '68px', borderRadius: 6 }}
                      />
                    </Stack>
                    <MenuComponent
                      onClick={() =>
                        toggleModal(ModalPages.INVITE_USER, {
                          modalProps: {
                            inviteUser: {
                              userEid: user.eid,
                              onSuccess: (userData: Types.User.iUser) => {
                                userData && updateQueryData(userData)
                                closeModal(ModalPages.INVITE_USER)
                              },
                            },
                          },
                        })
                      }
                    />
                  </Stack>
                </Stack>
              )
            })}
          </Stack>
        ) : (
          <TableContainer component={Stack}>
            <Table sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow sx={TABLE_HEADER_STYLES}>
                  <TableCell>NAME</TableCell>
                  <TableCell align="left">ROLE</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList?.map((user: Types.User.iUser & { profile_image: Types.User.iProfileImage | string | null }, i) => {
                  return (
                    <TableRow key={user.eid}>
                      <TableCell align="center" style={{ borderBottom: '1px solid #dedede' }} component="th" scope="row">
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          columnGap="24px"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch(setUser(user))
                            router.push(`/${workspace}/directory/users/${user.eid}`)
                          }}>
                          <Avatar
                            style={{
                              borderWidth: 2,
                              borderColor: GlobalStyles.MONO_WHITE,
                            }}
                            size={'lg'}
                            image={typeof user.profile_image == 'string' ? user.profile_image : user.profile_image?.full_url}
                            placeHolder={<Typography variant={'h5'}>{getInitials(user.name)}</Typography>}
                          />
                          <Typography fontSize="14px" color={GlobalStyles.MONO_BLACK} fontWeight={500}>
                            {user.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'caption'} fontSize="14px" color={GlobalStyles.SLATE_700}>
                          {user.roles ? `${user.roles.map((role) => role.label)}` : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack style={{ minHeight: '40px' }} className="justify-center">
                          <Label
                            type={'APPLIED'}
                            text={capitalizeFirstLetter('active')}
                            size={'base'}
                            textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY }}
                            style={{ width: '68px', borderRadius: 6 }}
                          />
                        </Stack>
                        {/* <Typography>live</Typography> */}
                      </TableCell>
                      <TableCell align={'center'}>
                        <MenuComponent
                          onClick={() =>
                            toggleModal(ModalPages.INVITE_USER, {
                              modalProps: {
                                inviteUser: {
                                  userEid: user.eid,
                                  onSuccess: (userData: Types.User.iUser) => {
                                    userData && updateQueryData(userData)
                                    closeModal(ModalPages.INVITE_USER)
                                  },
                                },
                              },
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <Stack alignItems="center" width="100%" marginY="10px">
        <Pagination
          page={queryParams.page}
          onChange={(_, page) => {
            router.push({ query: { ...router.query, page } }, undefined, { shallow: true })
            setQueryParams((q) => ({ ...q, page }))
          }}
          count={paginationData?.totalPage}
        />
      </Stack>
    </DirectoryContainer>
  )
}

UserDirectory.getLayout = function getLayout(page: ReactElement) {
  return <DirectoryLayout>{page}</DirectoryLayout>
}
