import { BlockType, LeafType, serialize } from 'remark-slate'
import { Editor, Transforms } from 'slate'
import { Types } from '@orbit'
import { MentionElement, MentionItem, MentionType } from '../mention-types'

const BASE_PATH = process.env.basePath || ''

export const CHAT_MENTIONS_QUERY_KEY = 'chat_mentions'

function insertMention(editor: Editor, mentionItem: MentionItem, mentionType: MentionType, workspaceSlug?: string) {
  const mention: MentionElement & { uid: string } = {
    mentionType,
    workspaceSlug,
    type: 'mention',
    uid: mentionItem.eid,
    fullName: mentionItem.name,
    children: [{ text: '' }],
  }

  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

function extractMetionedUsers<T extends Types.User.iUser[]>(children: Array<Partial<BlockType & LeafType>>) {
  return children
    .reduce<Array<{ eid: string; name: string }>>((mentions, child) => {
      if (child.type === 'mention') {
        const { uid, fullName } = child as MentionElement
        mentions.push({ eid: uid!, name: fullName! })
      }

      if (child.children) {
        mentions.push(...extractMetionedUsers(child.children))
      }

      return mentions
    }, [])
    .filter((mention, i, mentions) => {
      // filter out duplicate mentions
      return mentions.findIndex((m) => m.eid === mention.eid) === i
    }) as T
}

// @deprecated for old format of mentions
function extractContent(str: string) {
  const pattern = /@\{\[.*?\]\[.*?\]\}@/g
  const result: Array<{ text: string; isMention: boolean }> = []
  let lastIndex = 0
  let match: RegExpExecArray | null

  while ((match = pattern.exec(str)) !== null) {
    const matchedText = match[0]
    const matchedIndex = match.index

    if (matchedIndex >= lastIndex) {
      const segment = str.substring(lastIndex, matchedIndex)
      if (segment) result.push({ text: segment, isMention: false })

      // add a leading empty string if there is none yet for every mention
      if (!result.length || (result.length && (result[result.length - 1].isMention || result[result.length - 1].text === '\n'))) {
        result.push({ text: '', isMention: false })
      }

      result.push({ text: matchedText, isMention: true })
      lastIndex = pattern.lastIndex
    }
  }

  if (lastIndex < str.length) {
    const segment = str.substring(lastIndex)
    if (segment) result.push({ text: segment, isMention: false })
  }

  // add a trailing empty string if the last node is a mention
  if (result.length && result[result.length - 1].isMention) {
    result.push({ text: '', isMention: false })
  }

  return result.length ? result : [{ text: str, isMention: false }]
}

function extractContentFromLink(node: BlockType) {
  const { children } = node

  if (children[0]) {
    const text = (children[0] as LeafType).text
    const patterns = [
      { type: 'user', prefix: '@', regex: /\/directory\/users\/([^\/]+)$/ },
      { type: 'talent', prefix: '%', regex: /\/talent-profile\/([^\/]+)$/ },
      { type: 'job', prefix: '#', regex: /\/jobs\/([^\/]+)$/ },
    ]
    let mentionNode: MentionElement | null = null

    for (const pattern of patterns) {
      const isMatch = node.link?.match(pattern.regex)
      if (text.startsWith(pattern.prefix) && !!isMatch) {
        mentionNode = {
          type: 'mention',
          mentionType: pattern.type as MentionType,
          uid: isMatch[1],
          link: node.link,
          fullName: text,
          children: [{ text: '' }],
        }
      }
    }

    return mentionNode || node
  }

  return node
}

function parseMentionText(text: string) {
  const [content, mentionStyles] = text.match(/\[(.*?)\]/g) ?? []
  const [uid, fullName] = content?.slice(1, -1).split(':') ?? []
  const styles: Record<string, boolean> = {}

  if (mentionStyles) {
    mentionStyles
      .slice(1, -1)
      .split(',')
      .forEach((s) => {
        let style = ''

        if (s === 'b') {
          style = 'bold'
        } else if (s === 'i') {
          style = 'italic'
        } else if (s === 'u') {
          style = 'underline'
        }

        if (style) styles[style] = true
      })
  }

  return {
    type: 'mention',
    uid,
    fullName,
    children: [{ ...styles, text: '' }],
  } as MentionElement
}

function deserializeMention(nodeBlock: BlockType | LeafType) {
  let node = { ...nodeBlock }

  if (node.hasOwnProperty('children')) {
    node = node as BlockType
    let deserializedChildren: BlockType['children'] = []

    for (let i = 0; i < node.children.length; i++) {
      const deserializedResult = deserializeMention(node.children[i] as BlockType)

      if (Array.isArray(deserializedResult)) {
        deserializedChildren = [...deserializedChildren, ...deserializedResult]
      } else {
        deserializedChildren.push(deserializedResult)
      }
    }

    node.children = deserializedChildren
  }

  if (node.hasOwnProperty('text') || (node as BlockType).type === 'link') {
    if ((node as BlockType).type === 'link') {
      return extractContentFromLink(node as BlockType)
    }

    return extractContent((node as LeafType).text).map(({ text, isMention }) => {
      if (isMention) {
        return parseMentionText(text)
      }

      return { ...node, text }
    })
  }

  return node
}

function serializeMention(nodeBlock: BlockType) {
  const node = { ...nodeBlock }

  if (node.type === 'mention') {
    const { uid, children, fullName, mentionType, workspaceSlug } = node as MentionElement
    const hostURL = workspaceSlug ? `${BASE_PATH}/${workspaceSlug}` : BASE_PATH
    const firstChild = children[0] as LeafType & { underline?: boolean }
    const serializedText = serialize({ ...firstChild, text: fullName || '' })
    let prefix: string
    let linkUrl: string

    switch (mentionType) {
      case 'user':
        prefix = '@'
        linkUrl = `/directory/users/${uid}`
        break
      case 'talent':
        prefix = '%'
        linkUrl = `/talent-profile/${uid}`
        break
      case 'job':
        prefix = '#'
        linkUrl = `/jobs/${uid}`
        break
      default:
        prefix = '@'
        linkUrl = `/directory/users/${uid}`
    }

    // using the same format as the links from markdown
    return { text: `[${prefix + serializedText}](${hostURL + linkUrl})` }
  }

  if (node.children?.length) {
    node.children = node.children.reduce((serializedChildren: Array<BlockType | LeafType>, child, i, children) => {
      const nodeChild = { ...child } as BlockType

      if (nodeChild.children?.length) {
        nodeChild.children = nodeChild?.children.map((c) => serializeMention(c as BlockType))
      }

      if (nodeChild.type === 'mention') {
        // remove leading empty text node before mention, this will be added back when the mention is serialized
        if (serializedChildren.length && (serializedChildren[serializedChildren.length - 1] as LeafType).text === '') {
          serializedChildren.pop()
        }

        serializedChildren.push(serializeMention(child as BlockType))
      } else if (i < children.length - 1 || (child as LeafType).text !== '') {
        // remove trailing empty text node after mention, this will be added back when the mention is serialized
        serializedChildren.push(child)
      }

      return serializedChildren
    }, [])
  }

  return node
}

export { insertMention, deserializeMention, serializeMention, extractMetionedUsers }
