import { Avatar, ButtonV2, GlobalStyles, Icon, StackV2, Status, Text, Tooltip, View } from '@andromeda'
import { DrawerContext } from '@components'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Orbit, Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { fetchCurrentPermissions, logout, selectAuth, me as fetchMyProfile } from '@redux/reducers/authReducer'
import { resetJobsSlice } from '@redux/reducers/jobsReducer'
import { Workspace, getUserWorkspaceSettings, getWorkspaceSettings, selectWorkspace, setWorkspace } from '@redux/reducers/workspaceReducer'
import { AppStyles } from '@styles'
import { NavigationContext } from '@utils/context/Navigation.context'
import { getInitials } from '@utils/functions/helperFunctions'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { InnerDrawerMenuItem } from './MenuItem'
import { OnboardingModal } from '@modals'
import { trackEvent } from '@utils/tracking/helpers'
import { useMediaQuery } from '@mui/material'
import { UserMenu } from '../user/menu'
import { useMenu } from '@utils/hooks/useMenu'
import { useWorkspaces } from '@utils/query/useWorkspaces'
import { useMe } from '@utils/query/useMe'
import { breakpoints } from 'src/andromeda/helpers/theme'

const ICON_SIZE = 50

export const WorkspacesDrawer = () => {
  const { toggleWorkspaceDrawer: toggleOpen, autoCollapse, open, setDrawerOpen } = useContext(DrawerContext)
  const { goToDashboard, goToWorkBench, pathEnums } = useContext(NavigationContext)
  const breakpoint = useMediaQuery('(min-width:600px)')

  const { data: workspaceList } = useWorkspaces()

  const { workspace, activeWorkspace } = useAppSelector(selectWorkspace) // Get data from the selector
  const { token, status } = useAppSelector(selectAuth) // Get data from the selector
  const dispatch = useAppDispatch() // Reusable dispatcher
  const router = useRouter()
  const doFetchAllPermissions = useCallback(() => dispatch(fetchCurrentPermissions()), [dispatch]) // For dispatching Actions
  const doFetchMyProfile = useCallback(() => dispatch(fetchMyProfile()), [dispatch]) // For dispatching Actions

  const { data: me } = useMe()
  const userMenu = useMenu()

  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(me && !me.is_intro_completed)

  const doSetWorkspace = useCallback(
    (newWorkspace: string) => {
      switch (newWorkspace) {
        // case Workspace.dashboard:
        //     router.replace(`/${Workspace.dashboard}`)
        //     break
        // case Workspace.settings:
        //     // router.replace(`/${Workspace.settings}/my-account`)
        //     break
        // case Workspace.help:
        //     // router.replace(`/${Workspace.help}`)
        //     break
        case Workspace.workbench:
          // goToWorkBench()
          break
        default:
          Orbit.Services.AxiosBaseInstance.defaults.headers.common['x-pickstar-workspace'] = newWorkspace
          // goToDashboard(newWorkspace)
          break
      }
      if (newWorkspace !== workspace) {
        dispatch(resetJobsSlice())
      } else {
        if (setDrawerOpen && !breakpoint) setDrawerOpen(false)
      }

      setTimeout(() => {
        dispatch(setWorkspace(newWorkspace))
      }, 1000)
    },
    [dispatch, setDrawerOpen, breakpoint, workspace]
  ) // Dispatch setWorkspace action

  useEffect(() => {
    if (me) setIsOnboardingModalOpen(!me.is_intro_completed)
  }, [me, me?.is_intro_completed, status])

  useEffect(() => {
    if (setDrawerOpen) {
      setDrawerOpen(breakpoint)
    }
  }, [breakpoint])

  useEffect(() => {
    const { workspace: routerWorkspace } = router.query // Get dynamic route name
    const { pathname, route } = router // Get static level route name
    /**
     * Convert the route names into Workspace enum
     * If the string does not exist within enums, return value will be undefined.
     */
    const pathEnum = Workspace[pathname.replace('/', '') as Workspace]
    // const workspaceEnum = Workspace[((routerWorkspace ?? '') as String).replace('/', '') as Workspace]

    /**
     * Find if dynamic route workspace exists in store
     */
    const isWorkspaceExist = workspaceList?.find((space) => space.slug === routerWorkspace)

    /**
     * Check if user navigates to a sub route using a direct link eg: http://localhost:3000/settings/my-account
     * if so, extract the workspace using regex and set it
     */
    var regex = /\/(.*?)\//
    var matched = regex.exec(route) ?? []
    const subpathEnum = Workspace[((matched[1] ?? '') as String).replace('/', '') as Workspace]

    /**
     * Check if the new route contains a workspace param.
     * If so, that means the new route is a valid workspace specific route.
     *
     * Else , it means that the new route is a workspace agnostic route.
     *
     */
    if (isWorkspaceExist) {
      dispatch(setWorkspace(routerWorkspace as string))
    } else if (pathEnum) {
      dispatch(setWorkspace(pathEnum))
    } else if (subpathEnum) {
      dispatch(setWorkspace(subpathEnum))
    }
  }, [dispatch, router, workspaceList])

  useEffect(() => {
    if (activeWorkspace?.eid && token && router.isReady) {
      Orbit.Services.AxiosBaseInstance.defaults.headers.common['x-pickstar-workspace'] = activeWorkspace.slug
      if (token) {
        Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
      }
      dispatch(getWorkspaceSettings())
      dispatch(getUserWorkspaceSettings())
      doFetchAllPermissions()
      doFetchMyProfile()
    }
  }, [dispatch, activeWorkspace?.eid, token])

  const _renderWorkspaceList = useCallback(
    (space: Types.Workspaces.iWorkspace) => {
      return (
        <Link key={space.eid} href={`/${space.slug}`}>
          <InnerDrawerMenuItem
            title={space.name}
            menuItemStyle={{ borderRadius: 0 }}
            type={'workspace'}
            action={() => {
              doSetWorkspace(space.slug)
              if (isMobile && toggleOpen) toggleOpen()
            }}
            selected={workspace === space.slug && !router.route.includes(pathEnums.WORKBENCH)}
            icon={
              <>
                {space.logo_image?.full_url ? (
                  <Image
                    alt="WorkspaceLogo"
                    style={{ borderRadius: GlobalStyles.BORDER_RADIUS }}
                    src={space.logo_image?.full_url ?? ''}
                    height={40}
                    width={40}
                  />
                ) : (
                  <Tooltip text={space.name} showIcon={false} stackProps={{ direction: { xxs: 'row', sm: 'column' } }}>
                    <Text
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        fontSize: GlobalStyles.FONT_SIZES.XTRA_LARGE,
                        color: GlobalStyles.MONO_WHITE,
                      }}>
                      {getInitials(space.name)}
                    </Text>
                  </Tooltip>
                )}
              </>
            }
          />
        </Link>
      )
    },
    [doSetWorkspace, workspace]
  )

  return (
    <StackV2
      id="global-menu"
      alignItems={{ xxs: 'flex-start', sm: 'center' }}
      paddingTop={'env(safe-area-inset-top)'}
      paddingBottom="env(safe-area-inset-bottom)"
      style={{ backgroundColor: GlobalStyles.MONO_BLACK }}
      width={{ xxs: '100%', sm: AppStyles.MENU_ITEM_HEIGHT.sm }}
      display={open ? 'flex' : 'none'}
      flexShrink={0}
      sx={{
        '& a': { width: 'inherit' },
        '& .MuiStack-root': { width: 'inherit' },
      }}>
      {!router.pathname.includes('workbench') && workspaceList?.length ? (
        <IconButton
          aria-label={open ? 'Minimize' : 'Maximize'}
          sx={{
            height: AppStyles.MENU_ITEM_HEIGHT,
            width: AppStyles.MENU_ITEM_HEIGHT,
            padding: 0,
            backgroundColor: GlobalStyles.MONO_BLACK,
            borderRadius: 0,
          }}
          onClick={() => toggleOpen && toggleOpen()}>
          {open ? (
            <>
              <StackV2 display={{ xxs: 'none', sm: 'flex' }} alignItems="center">
                <Icon.ChevronLeft color="white" />
              </StackV2>
              <StackV2 display={{ xxs: 'flex', sm: 'none' }} alignItems="center">
                <Icon.X color="white" />
              </StackV2>
            </>
          ) : (
            <Icon.Menu color="white" />
          )}
        </IconButton>
      ) : (
        <></>
      )}
      <Link href={`${pathEnums.WORKBENCH}`}>
        <InnerDrawerMenuItem
          title="My Workbench"
          type={'workspace'}
          action={() => {
            // doSetWorkspace(Workspace.workbench)
          }}
          selected={router.route.includes(pathEnums.WORKBENCH)}
          icon={<Icon.Layout color={GlobalStyles.MONO_WHITE} />}
          menuItemStyle={{ flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}
        />
      </Link>
      {!!workspaceList?.length ? (workspaceList.map(_renderWorkspaceList) as unknown as JSX.Element) : <></>}
      <StackV2 flex={1} justifyContent={'flex-end'} spacing={2}>
        <Divider variant={'fullWidth'} />
        <Box display={{ xxs: 'flex', sm: 'none' }} alignItems={'center'} paddingX={'16px'} boxSizing="border-box">
          <Typography variant={'caption'} style={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.SLATE_100 }}>
            You are logged in as {me?.name}.
          </Typography>
          <ButtonV2
            onClick={() => dispatch(logout())}
            variant="text"
            color={'slate_400'}
            style={{ textDecorationLine: 'underline', flexShrink: 0 }}>
            Log out
          </ButtonV2>
        </Box>
        <StackV2
          direction={{ xxs: 'row', sm: 'column' }}
          justifyContent={'flex-start'}
          rowGap="12px"
          paddingX={{ xxs: '10px', sm: 0 }}
          paddingBottom={{ xxs: '12px', sm: 0 }}
          boxSizing="border-box">
          {/* <InnerDrawerMenuItem
                        type={'default'}
                        menuItemStyle={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
                        action={() => {
                            doSetWorkspace(Workspace.settings)
                        }}
                        selected={workspace === Workspace.settings}
                        icon={<Icon.Settings color={GlobalStyles.MONO_WHITE} />}
                    /> */}
          <Box onClick={userMenu.handleClick}>
            <InnerDrawerMenuItem
              type={'default'}
              menuItemStyle={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
              action={() => {}}
              selected={false}
              icon={
                <Avatar
                  style={{ borderWidth: 2, borderColor: GlobalStyles.MONO_WHITE }}
                  size={'md'}
                  image={me?.profile_image?.full_url || undefined}
                  placeHolder={<Icon.User size={AppStyles.AVATAR_SIZES.DEFAULT} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
                />
              }
            />
            <UserMenu
              anchorEl={userMenu.anchorEl}
              handleClose={(e) => {
                if (isMobile && toggleOpen) toggleOpen()
                userMenu.handleClose(e)
              }}
            />
          </Box>
          <InnerDrawerMenuItem
            title="Help"
            type={'default'}
            menuItemStyle={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
            action={() => {
              trackEvent({ event: 'Opened Help Modal', eventProperties: { workspace } })
              !isOnboardingModalOpen && setIsOnboardingModalOpen(true)
            }}
            selected={false}
            icon={
              <>
                <Icon.HelpCircle color={GlobalStyles.MONO_WHITE} size={20} />
                {isOnboardingModalOpen && <OnboardingModal open={isOnboardingModalOpen} onClose={() => setIsOnboardingModalOpen(false)} />}
              </>
            }
          />
          {/* <Link href={{ pathname: pathEnums.HELP, query: { workspace } }}>
                        <InnerDrawerMenuItem
                            type={'default'}
                            menuItemStyle={{ backgroundColor: GlobalStyles.COLOR_TRANSPARENT }}
                            action={() => {
                                // doSetWorkspace(Workspace.help)
                            }}
                            selected={workspace === Workspace.help}
                            icon={<Icon.HelpCircle size={30} color={GlobalStyles.MONO_WHITE} />}
                        />
                    </Link> */}
        </StackV2>
      </StackV2>
    </StackV2>
  )
}
