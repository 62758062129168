export default function deepObjectCompare(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
  // Check if both arguments are strictly equal
  if (obj1 === obj2) return true

  // Check if either argument is null or not an object
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) return false

  // Check each key and value recursively
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepObjectCompare(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}
