import { GlobalStyles, StackV2, Text, View } from '@andromeda'
import { Container, LoginComponent, Main } from '@components'
import { Stack } from '@mui/material'
import Image from 'next/legacy/image'
import PickyLogo from 'public/pickstar-works-banner.png'
import { ReactElement } from 'react'
export default function Login<NextPage>({ children }: { children?: JSX.Element }) {
  return (
    <StackV2 direction={'row'} style={{ width: '100vw', height: `100vh` }}>
      <div style={{ backgroundColor: '#464C5D', borderRight: '10px solid #FF6138' }} className={'h-full w-[300px] hidden md:flex'} />
      <StackV2
        flex={1}
        width={'100%'}
        padding={{ xxs: 5, md: 0 }}
        paddingTop={{ xxs: 'calc(env(safe-area-inset-top) + 40px)' }}
        direction="column"
        justifyContent={{ sm: 'center' }}>
        <StackV2 width={'100%'} alignItems={{ sm: 'center' }} sx={{ overflowY: 'auto' }}>
          <StackV2 direction="column" height={'auto'} alignItems={'flex-start'} rowGap="38px">
            <Stack style={{ display: 'block', width: '100%', maxWidth: 300 }}>
              <Image alt="WorkspaceLogo" src={PickyLogo} layout="responsive" objectFit={'contain'} priority={true} />
            </Stack>
            {children ?? (
              <>
                <StackV2 direction="column" rowGap={{ xxs: '6px', sm: '38px' }} alignItems={'flex-start'}>
                  <Text
                    style={{
                      color: GlobalStyles.MONO_BLACK,
                      fontWeight: '500',
                      fontSize: 24,
                      textAlign: 'left',
                    }}>
                    Welcome
                  </Text>
                  <Text size={'base'} style={{ color: GlobalStyles.SLATE_700, textAlign: 'left' }}>
                    Please sign in to your account.
                  </Text>
                </StackV2>
                <LoginComponent />
              </>
            )}
          </StackV2>
        </StackV2>
      </StackV2>
    </StackV2>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return page
}
