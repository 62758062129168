import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Stack, Switch, Typography, useMediaQuery } from '@mui/material'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useState } from 'react'
import { ViewColumn } from '@mui/icons-material'
import { breakpoints } from 'src/andromeda/helpers/theme'

type Column = {
  label: string
  subtext?: string
  field: string
  enabled: boolean
  isCustomField?: boolean
}

export type ColumnControlProps = {
  columns: Column[]
  maxColumns: number
  onSave: (columns: Column[], setIsLoading: (value: boolean) => void) => void
}

const ColumnControl = () => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)
  const { options } = useContext(ModalContext)
  const props = options?.modalProps?.columnControl
  const [isLoading, setIsLoading] = useState(false)
  const [newColumns, setNewColumns] = useState(props?.columns ? structuredClone(props.columns) : [])
  const MAX_COLUMNS = props?.maxColumns || 2
  const NUMBER_OF_ENABLED = newColumns.filter((column) => column.enabled).length

  const _handleSave = () => {
    if (props && props.onSave) {
      props.onSave(newColumns, setIsLoading)
    }
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '600px' }}>
      <ModalHeader
        leftIcon={<ViewColumn htmlColor={GlobalStyles.SLATE_400} />}
        modalTitle="Manage Columns"
        modalContext={ModalPages.COLUMN_CONTROL}
      />
      <Stack className="dialog-content" rowGap="8px">
        <Stack style={{ border: `1px solid ${GlobalStyles.SLATE_100}`, borderRadius: '6px' }}>
          {newColumns.map((column, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              style={{ borderTop: index !== 0 ? `1px solid ${GlobalStyles.SLATE_100}` : undefined }}>
              <Typography width={'86px'} style={{ padding: '24px 32px', minWidth: '86px' }}>
                {column.label}
              </Typography>
              <Stack
                direction="row"
                alignSelf="stretch"
                alignItems="center"
                columnGap="8px"
                flex={1}
                style={{ backgroundColor: GlobalStyles.SLATE_50, padding: '24px 32px', borderLeft: `1px solid ${GlobalStyles.SLATE_100}` }}>
                <Typography style={{ flex: 1, color: GlobalStyles.SLATE_700 }}>{column.subtext}</Typography>
                <Stack direction="row" alignItems="center" justifyContent="end" columnGap="8px">
                  <Switch
                    id={`${column.field}-enabled`}
                    onChange={(e) => setNewColumns((prev) => prev.map((c, i) => (i === index ? { ...c, enabled: e.target.checked } : c)))}
                    checked={column.enabled}
                    disabled={NUMBER_OF_ENABLED >= MAX_COLUMNS && !column.enabled}
                  />
                  {!isMobile && <Typography>{column.enabled ? 'enabled' : 'disabled'}</Typography>}
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent={NUMBER_OF_ENABLED >= MAX_COLUMNS ? 'space-between' : 'end'}
        columnGap="8px"
        className="dialog-footer"
        alignItems="center">
        {NUMBER_OF_ENABLED >= MAX_COLUMNS && (
          <Typography>A maximum of {MAX_COLUMNS} fields can be shown at a time, disable to allow selection</Typography>
        )}

        <Button variant="contained" onClick={_handleSave} disabled={isLoading || !NUMBER_OF_ENABLED} style={{ minWidth: '70px' }}>
          {isLoading ? <CircularProgress size={12} style={{ marginRight: `${GlobalStyles.MARGIN_SIZES['2xs']}px` }} /> : undefined}
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Stack>
    </Stack>
  )
}

export default ColumnControl
