import { memo, useState, useEffect, useCallback } from 'react'
import { ProgressBar } from 'react-native'
import { GlobalStyles } from '../globalStyles'
import { BarProps, BarDefaults } from './types'
import { View } from '../View'
import { BarStyles } from './styles'

const Bar = ({
  style,
  type,
  progressCount = BarDefaults.PROGRESS_COUNT,
  maxProgressCount = BarDefaults.MAX_PROGRESS_COUNT,
  barWidth = BarDefaults.WIDTH,
  size = BarDefaults.SIZE,
  accessibilityLabel,
  ...props
}: BarProps) => {
  const [width, setWidth] = useState<number>(0)

  const color = () => {
    let c: string

    switch (type) {
      case 'blue':
        c = GlobalStyles.PRIMARY_500
        break
      case 'yellow':
        c = GlobalStyles.STATUS_YIELD
        break
      case 'slate':
        c = GlobalStyles.MONO_BLACK
        break
      case 'danger':
        c = GlobalStyles.STATUS_NEGATIVE
        break
      case 'grey':
        c = GlobalStyles.SLATE_300
        break

      default:
        c = GlobalStyles.STATUS_POSITIVE
    }

    return c
  }

  const _getWidth = useCallback(() => {
    // get the % equivalent of each progressCount
    const portionWidth = 100 / maxProgressCount

    setWidth((portionWidth * progressCount) / 100)
  }, [progressCount, maxProgressCount])

  useEffect(() => {
    _getWidth()
  }, [progressCount, maxProgressCount])

  return (
    <View style={[BarStyles.wrapper, { width: barWidth, borderColor: color() }]}>
      <ProgressBar
        aria-label={accessibilityLabel}
        color={color()}
        progress={width}
        indeterminate={props.indeterminate}
        style={[BarStyles.innerLoader, props.innerBarStyle]}
      />
    </View>
  )
}

export default memo(Bar)
