import { GlobalStyles, Icon } from '@andromeda'
import { Stack, TableCell, Tooltip, Typography } from '@mui/material'
import { formatHourAndMinutesToText } from '@utils/functions/helperFunctions'
import { iTalent } from '@utils/types/talent'

export default function PlayerAppearanceLevelRenderer({ talent, inline = false }: { talent: iTalent; inline?: boolean }) {
  if (!talent.stats) return <span>-</span>

  const { deliverable_times, completed_jobs } = talent.stats

  return (
    <Stack direction={inline ? 'row' : 'column'} rowGap="4px" columnGap="4px">
      <Typography variant="caption" whiteSpace="nowrap" lineHeight="14px" fontSize="14px" color={GlobalStyles.SLATE_700}>
        <Tooltip title="Jobs completed" arrow>
          <span>
            <Icon.Layers size={14} style={{ marginBottom: '-2px' }} />
            &nbsp;{completed_jobs}
          </span>
        </Tooltip>
      </Typography>

      <Typography variant="caption" whiteSpace="nowrap" lineHeight="14px" fontSize="14px" color={GlobalStyles.SLATE_700}>
        <Tooltip title="Total hours completed" arrow>
          <span>
            <Icon.Clock size={14} style={{ marginBottom: '-2px' }} />
            &nbsp;{formatHourAndMinutesToText(deliverable_times)}
          </span>
        </Tooltip>
      </Typography>
    </Stack>
  )
}
