import { DropdownOption, GlobalStyles, Icon, RichEditor, RichReader, Tooltip } from '@andromeda'
import { Box, Divider, Grid, Stack, Typography, autocompleteClasses, inputBaseClasses, outlinedInputClasses } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getTypes, setActiveDeliverable, selectDeliverable } from '@redux/reducers/deliverableReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { EditIconContext, EditIconProvider } from '@utils/context/EditIcon.context'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isEditableContext } from '.'
import { fetchDeliverableUsers, resetViewedDeliverableUsers } from '@redux/reducers/jobsReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { useEscape } from '@utils/hooks/useEscape'
import { Attachment } from 'src/components/attachments/attachments'
import { FormSearchTagV2 } from 'src/components/search/searchtag.new'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { Textfield } from 'src/components/form/Textfield'
import { FormDateTimePicker } from 'src/components/form/DateTimePicker'
import { CurrencyTextfield } from 'src/components/form/CurrencyTextfield'
import { DevTool } from '@hookform/devtools'
import { useCurrencyFormat } from '@utils/hooks/useCurrencyFormat'
import { Posts } from 'src/components/social-campaigns/post'
import CustomFields from 'src/components/custom-fields'
import { FormAutoComplete } from 'src/components/form/AutoComplete'
import { formatLocation } from '@utils/functions/helperFunctions'
import { FormAutoCompleteLocation } from 'src/components/autoCompleteLocation'

export const labelStyles = {
  color: GlobalStyles.MONO_BLACK,
  fontSize: GlobalStyles.FONT_SIZES.SMALL,
  lineHeight: '20px',
  fontWeight: 500,
}

export const dropdownStyles = {
  labelStyle: { color: GlobalStyles.PRIMARY_500 },
  dropdownContainerStyle: { height: '30px', padding: 0, borderRadius: 6 },
  stackProps: { style: { width: '100%' } },
}

export const Label = ({ text, tooltipText = '' }: { text: string; tooltipText?: string }) => (
  <>
    <Typography {...labelStyles}>{text}</Typography>
    <Tooltip text={tooltipText} />
  </>
)
export const CommonGrid = ({
  text,
  onClick,
  readonlyEl,
  editableEl,
  children,
  tooltipText = '',
  isTextInput = false,
}: {
  text: string
  readonlyEl?: JSX.Element
  editableEl?: JSX.Element
  children?: JSX.Element
  onClick: () => void
  tooltipText?: string
  isTextInput?: boolean
}) => {
  const { state: isEditable } = useContext(isEditableContext)
  const { setVisibleEditIcon, visibleEditIcon } = useContext(EditIconContext)

  return (
    <Stack direction="row" columnGap="8px" onMouseEnter={() => setVisibleEditIcon(text)} onMouseLeave={() => setVisibleEditIcon(null)}>
      <Stack direction="row" columnGap="8px" alignItems="center" minWidth="130px">
        <Label text={text} tooltipText={tooltipText} />
      </Stack>
      <Stack direction="row" flex={1} onClick={onClick} sx={{ cursor: 'pointer' }} className="items-center justify-between">
        {children || (isEditable ? editableEl : readonlyEl)}
        {isTextInput && visibleEditIcon === text && !isEditable && <Icon.Edit2 size={16} color={GlobalStyles.PRIMARY_500} />}
      </Stack>
    </Stack>
  )
}
interface Props {
  deliverable: Types.Deliverable.iDeliverable
  cost_mode: Types.Job.iJob['cost_mode']
}

export type DeliverableOverviewFormState = {
  name: string | null
  eid?: string
  selectedCategory?: { id: string; value: string } | DropdownOption
  selectedType?: { id: string; value: string } | DropdownOption
  number_of_talents_required?: number
  description: string | null
  start_date?: moment.Moment | null
  start_time?: moment.Moment | null
  end_date?: moment.Moment | null
  end_time?: moment.Moment | null
} & Pick<Types.Deliverable.iDeliverable, 'deliverable_cost_per_talent' | 'deliverable_currency_code'>

export const OverviewTab = ({ deliverable, cost_mode }: Props) => {
  const dispatch = useAppDispatch()
  const { types } = useAppSelector(selectDeliverable)
  const { settings: workspaceSettings, workspace } = useAppSelector(selectWorkspace)

  const { features } = useContext(FeatureFlagsContext)
  const { state: isEditable, setState: setIsEditable } = useContext(isEditableContext)

  const categoryOptions = useMemo(() => (types ? types.map(({ eid, name }) => ({ id: eid, value: name })) : []), [types])

  const isDeliverableCostMode = useMemo(() => cost_mode === 'PER_DELIVERABLE', [cost_mode])
  const currencyCodeOptions = useCurrencyCode()

  const [visibleEditIcon, setVisibleEditIcon] = useState<string | null>(null)

  const defaultCurrencyCode = useMemo(() => {
    return currencyCodeOptions.find((option) =>
      deliverable.deliverable_currency_code
        ? option.value === deliverable.deliverable_currency_code
        : option.id === workspaceSettings?.default_currency
    )
  }, [currencyCodeOptions, deliverable.deliverable_currency_code, workspaceSettings?.default_currency])

  useEscape(() => setIsEditable(false), isEditable, 'deliverable')
  const formatCurrency = useCurrencyFormat()

  const form = useFormContext()

  const { field: startDateField } = useController({ name: 'start_date', control: form.control, rules: { required: true } })
  const { field: endDateField } = useController({ name: 'end_date', control: form.control, rules: { required: true } })
  const { field: descriptionField } = useController({ name: 'description', control: form.control })
  const { field: selectedTypeField } = useController({ name: 'selectedType', control: form.control })
  const formDeliverableEid = useWatch({ control: form.control, name: 'eid' })
  const formSelectedCategory = useWatch({ control: form.control, name: 'selectedCategory' })
  const {
    field: { value: locationValue, onChange: locationChange },
  } = useController({
    name: 'location',
    control: form.control,
  })

  const typeOptions = useMemo(() => {
    const children = types ? types.find(({ eid }) => eid === formSelectedCategory?.id)?.children : []

    return children ? children.map(({ eid, name }) => ({ id: eid, value: name })) : []
  }, [formSelectedCategory, types])

  useEffect(() => {
    if (!form.getValues('deliverable_currency_code')) {
      const DEFAULT_CURRENY_OBJ = currencyCodeOptions.find((option) =>
        deliverable.deliverable_currency_code
          ? option.value === deliverable.deliverable_currency_code
          : option.id === workspaceSettings?.default_currency
      )

      if (DEFAULT_CURRENY_OBJ) {
        form.setValue('deliverable_currency_code', DEFAULT_CURRENY_OBJ)
      }
    }
  }, [currencyCodeOptions, deliverable.deliverable_currency_code, workspaceSettings?.default_currency, form])

  useEffect(() => {
    if (!types) dispatch(getTypes())
  }, [dispatch, types])

  useEffect(() => {
    if (deliverable) dispatch(setActiveDeliverable(deliverable))

    return () => {
      dispatch(setActiveDeliverable(null))
    }
  }, [dispatch, deliverable])

  useEffect(() => {
    if (deliverable.eid && features.isDeliverabeUsersEnabled) dispatch(fetchDeliverableUsers({ deliverableEid: deliverable.eid }))

    return () => {
      dispatch(setActiveDeliverable(null))
    }
  }, [dispatch, deliverable.eid, features.isDeliverabeUsersEnabled])

  const handleSetIsEditable = () => {
    setIsEditable(true)
  }

  return (
    <EditIconProvider>
      <DevTool control={form.control} placement="top-left" />
      <Stack gap="24px">
        <Stack
          border={`1px solid ${GlobalStyles.SLATE_100}`}
          borderRadius="6px"
          padding="12px"
          gap="14px"
          sx={{
            [`.${autocompleteClasses.root} `]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 0,
              },
              [`.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 1,
              },
              [`.${autocompleteClasses.input}`]: {
                color: GlobalStyles.PRIMARY_500,
              },
            },
          }}>
          <Box display="flex" gap="12px" alignItems="center">
            <Icon.Eye color={GlobalStyles.SLATE_500} width={20} height={20} />
            <Typography {...labelStyles} color={GlobalStyles.SLATE_500}>
              {' '}
              DELIVERABLE OVERVIEW
            </Typography>
          </Box>
          <CommonGrid
            text="Category"
            tooltipText="Select the best fit category for what you would like the talent(s) to do."
            onClick={handleSetIsEditable}>
            <FormAutoComplete
              name="selectedCategory"
              options={categoryOptions}
              rules={{ required: true }}
              defaultValue={
                deliverable.deliverable_type.category
                  ? { id: deliverable.deliverable_type.category_eid, value: deliverable.deliverable_type.category }
                  : undefined
              }
              getOptionLabel={(option) => option.value}
              onChangeCB={() => {
                form.setValue('selectedType', undefined)
              }}
            />
          </CommonGrid>
          <CommonGrid
            text="Type"
            tooltipText="Choose a deliverable type to better indicate the requirements for talent."
            onClick={handleSetIsEditable}>
            <FormAutoComplete
              name="selectedType"
              options={typeOptions}
              rules={{ required: 'Please select a different type' }}
              defaultValue={
                deliverable.deliverable_type
                  ? { id: deliverable.deliverable_type.eid, value: deliverable.deliverable_type.name }
                  : undefined
              }
              getOptionLabel={(option) => option.value}
            />
          </CommonGrid>
          <Divider sx={{ backgroundColor: GlobalStyles.SLATE_100 }} />
          {isDeliverableCostMode && (
            <>
              <CommonGrid
                text="Talent Required"
                tooltipText="Add how many talent you are ideally looking to book for this job/deliverable"
                onClick={handleSetIsEditable}
                isTextInput
                readonlyEl={
                  <Typography
                    {...labelStyles}
                    color={deliverable?.number_of_talents_required ? GlobalStyles.SLATE_700 : GlobalStyles.SLATE_300}
                    fontWeight={400}>
                    {deliverable?.number_of_talents_required ? deliverable?.number_of_talents_required : '0'}
                  </Typography>
                }
                editableEl={
                  <Textfield
                    type="number"
                    placeholder="Talent Required"
                    name="number_of_talents_required"
                    registerOptions={{ required: true }}
                  />
                }
              />
              <CommonGrid
                text="Cost per talent"
                tooltipText=""
                onClick={handleSetIsEditable}
                isTextInput
                readonlyEl={
                  <Typography {...labelStyles} color={GlobalStyles.SLATE_700} fontWeight={400}>
                    {formatCurrency(defaultCurrencyCode?.value, deliverable?.deliverable_cost_per_talent)}
                  </Typography>
                }
                editableEl={
                  <CurrencyTextfield
                    currency={{ name: 'deliverable_cost_per_talent', type: 'number' }}
                    currencyCode={{ name: 'deliverable_currency_code', options: currencyCodeOptions }}
                  />
                }
              />
            </>
          )}

          <CommonGrid
            text="Location"
            tooltipText=""
            onClick={handleSetIsEditable}
            isTextInput
            readonlyEl={<>{formatLocation(deliverable.location || '')}</>}
            editableEl={
              <FormAutoCompleteLocation name="location" onChange={locationChange} defaultValue={formatLocation(deliverable.location)} />
            }
          />
          <Grid
            container
            gap="8px"
            display="flex"
            onClick={handleSetIsEditable}
            sx={{ cursor: isEditable ? '' : 'pointer' }}
            onMouseEnter={() => setVisibleEditIcon('description')}
            onMouseLeave={() => setVisibleEditIcon(null)}>
            <Grid item gap="12px" xxs={12}>
              <Stack direction="row" className="items-center justify-between">
                <Label
                  text="Description"
                  tooltipText="Add extra details that talent will need to know in order to fullfill this deliverable/job."
                />
                <Icon.Edit2
                  size={16}
                  color={GlobalStyles.PRIMARY_500}
                  style={{ visibility: visibleEditIcon === 'description' && !isEditable ? 'visible' : 'hidden' }}
                />
              </Stack>
            </Grid>
            <Grid item xxs={12}>
              {isEditable ? (
                <RichEditor
                  initialValue={descriptionField.value ?? ''}
                  showFooter={false}
                  onChange={(val, plaintext) => {
                    descriptionField.onChange(val)
                    form.setValue('plain_description', plaintext)
                  }}
                  showInlineSendButton={false}
                />
              ) : (
                <RichReader initialValue={deliverable.description ?? ''} />
              )}
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: GlobalStyles.SLATE_100 }} />

          <CommonGrid
            text="Start date"
            tooltipText="Add a date so talent can assess availability and recieve reminders to complete the deliverable."
            onClick={handleSetIsEditable}
            isTextInput
            readonlyEl={
              <Typography {...labelStyles} color={GlobalStyles.SLATE_700} fontWeight={400}>
                {deliverable.start_datetime
                  ? moment(new Date(deliverable.start_datetime as string)).format(workspaceSettings?.date_format)
                  : ''}
              </Typography>
            }
            editableEl={
              <FormDateTimePicker
                format={workspaceSettings?.date_format}
                slotProps={{
                  textField: {
                    error: !!form.formState.errors.start_date,
                  },
                }}
                {...startDateField}
              />
            }
          />
          <CommonGrid
            text="End date"
            tooltipText="Add a date so talent can assess availability and recieve reminders to complete the deliverable."
            onClick={handleSetIsEditable}
            isTextInput
            readonlyEl={
              <Typography {...labelStyles} color={GlobalStyles.SLATE_700} fontWeight={400}>
                {deliverable.end_datetime
                  ? moment(new Date(deliverable.end_datetime as string)).format(workspaceSettings?.date_format)
                  : ''}
              </Typography>
            }
            editableEl={
              <FormDateTimePicker
                format={workspaceSettings?.date_format}
                slotProps={{
                  textField: {
                    error: !!form.formState.errors.start_date,
                  },
                }}
                {...endDateField}
              />
            }
          />
        </Stack>
        <Attachment deliverableEid={deliverable.eid} files={deliverable.files} />

        {features.isDeliverableCustomFieldsEnabled && !!workspaceSettings?.custom_field_settings?.length && (
          <CustomFields
            section="deliverable"
            columnNo={1}
            defaultValues={deliverable.custom_field_data}
            isEdit={isEditable}
            onInputFieldClick={handleSetIsEditable}
          />
        )}

        {features.isSocialCampaignsEnabled && workspaceSettings?.social_metrics && <Posts />}
      </Stack>
    </EditIconProvider>
  )
}
