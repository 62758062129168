import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Avatar, Box, Button, Grid, Stack, Typography, outlinedInputClasses } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import {
  createTalent,
  selectTalentProfile,
  setDetails,
  updateManagement,
  updateProfilePicture,
  updateTalent,
} from '@redux/reducers/talentProfileReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useEscape } from '@utils/hooks/useEscape'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { iTalentProfileDetails } from '@utils/types/talent-profile'
import { useRouter } from 'next/router'
import { createContext, useContext } from 'react'
import { SubmitHandler, useController, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Paper } from '../job-details/components'
import { BreakpointContext, Row } from './profile'
import { AutoCompleteLocation } from '../autoCompleteLocation'
import { formatLocation } from '@utils/functions/helperFunctions'
import { Textfield } from '../form/Textfield'
import { SocialCampaign } from '../social-campaigns/talent'
import { decodeCustomFieldsValue } from '../custom-fields'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'

export type iProfileForm = Pick<
  iTalentProfileDetails,
  'display_name' | 'location' | 'bio' | 'eid' | 'role' | 'profile_image_eid' | 'custom_fields'
>

export const isEditableContext = createContext<{ state: boolean; setState: (editable: boolean) => false | void }>({
  state: false,
  setState: () => {},
})

const CommonGrid = ({
  label,
  tooltipText = '',
  value = '',
  borderRight = false,
}: {
  value?: string | JSX.Element | null
  label: string
  tooltipText?: string
  borderRight?: boolean
}) => {
  const { isMobileWidth } = useContext(BreakpointContext)

  return (
    <Grid
      item
      xxs={12}
      lg={isMobileWidth ? 12 : 6}
      display="flex"
      alignItems="center"
      paddingY="12px"
      borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}
      borderRight={borderRight && !isMobileWidth ? `1px solid ${GlobalStyles.SLATE_100}` : ''}>
      <Grid container>
        <Grid
          item
          xxs={12}
          sm={isMobileWidth ? 12 : 4}
          display="flex"
          alignItems="center"
          gap="4px"
          paddingX={{ xxs: '12px', sm: isMobileWidth ? '12px' : '24px' }}>
          <Typography variant="caption" color={GlobalStyles.MONO_BLACK} fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
            {label}
          </Typography>
          <Box display={{ xxs: 'none', sm: 'initial' }}>
            <Tooltip text={tooltipText} />
          </Box>
        </Grid>
        <Grid item xxs={12} sm={isMobileWidth ? 12 : 8} paddingX={{ xxs: '12px', sm: isMobileWidth ? '12px' : '24px' }}>
          {typeof value === 'string' ? <Typography color={GlobalStyles.SLATE_700}>{value}</Typography> : value}
        </Grid>
      </Grid>
    </Grid>
  )
}

export const TalentForm = ({
  talentId,
  onSuccess,
  agreementEl,
}: {
  talentId?: string
  onSuccess?: () => void
  agreementEl?: JSX.Element
}) => {
  const router = useRouter()
  const { workspace } = router.query

  const dispatch = useAppDispatch()
  const { details, aboutMe, managers, apiStatus } = useAppSelector(selectTalentProfile)
  const { settings } = useAppSelector(selectWorkspace)

  const { state: isEditable, setState: handleSetIsEditable } = useContext(isEditableContext)
  const { toggleModal, options: modalOptions } = useContext(ModalContext)
  const { features } = useContext(FeatureFlagsContext)

  const { isMobileWidth } = useContext(BreakpointContext)

  const paddingX = { xxs: '12px', sm: isMobileWidth ? '12px' : '24px' }
  const paddingY = '12px'

  // React Hook Form Actions
  const form = useFormContext<iProfileForm>()
  const {
    handleSubmit: doSubmit,
    control,
    reset,
    register,
    formState: { dirtyFields, isDirty, errors },
  } = form
  const {
    field: { value: locationValue, onChange: locationChange },
  } = useController<iProfileForm>({ name: 'location', control })

  const onSubmit: SubmitHandler<iProfileForm> = (data) => {
    const changes = Object.fromEntries(Object.keys(dirtyFields).map((key: any) => [key, data[key as keyof iProfileForm]]))

    if (data?.custom_fields) {
      data.custom_fields = decodeCustomFieldsValue(data.custom_fields)
    }

    if (talentId) {
      toast
        .promise(
          dispatch(
            updateTalent({
              payload: changes,
              eid: talentId as string,
            })
          ),
          { pending: 'Saving changes...' }
        )
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Changes has been saved.')
            trackEvent({
              event: TrackingEventEnums.Profile.PROFILE_UPDATE_PROFILE,
              eventProperties: {
                workspace: workspace,
                component_name: 'Updated Profile',
                profile_eid: talentId,
              },
            })
            // dispatch(viewTalent({ eid: talentId as string }))
            onSuccess && onSuccess()

            handleSetIsEditable(false)
          } else {
            toast.error('Changes were not saved. Please try again.')
          }
        })
    } else {
      const createTalentDetails: Types.Talent.iTalentCreatePayload = changes
      if (details.profile_image_eid) createTalentDetails.profile_image_eid = details.profile_image_eid

      toast
        .promise(dispatch(createTalent(createTalentDetails)), {
          pending: 'Saving talent profile...',
        })
        .then((response) => {
          const { eid } = response.payload.data
          if (response.meta.requestStatus === 'fulfilled') {
            if (managers?.length) {
              const talentManagers: Types.Talent.iTalentManagementPayload = {
                management: {},
              }

              managers?.forEach((user) => {
                if (user.management?.management_level) {
                  talentManagers.management[user.eid] = user.management.management_level
                }
              })

              toast.promise(dispatch(updateManagement({ eid, payload: talentManagers })), {}).then((response) => {
                if (response.meta.requestStatus === 'fulfilled') toast.success('Talent profile has been saved.')
                else toast.error('Changes were not saved. Please try again.')
                router.push(`/${workspace}/talent-profile/${eid}`)
              })
            } else {
              toast.success('Talent profile has been saved.')
              trackEvent({
                event: TrackingEventEnums.Profile.PROFILE_CREATE_PROFILE,
                eventProperties: {
                  workspace: workspace,
                  component_name: 'Created Profile',
                  profile_eid: eid,
                },
              })
              router.push(`/${workspace}/talent-profile/${eid}`)
            }
          } else {
            toast.error('Changes were not saved. Please try again.')
          }
        })
        .catch((err) => {})
    }
  }

  useEscape(() => handleSetIsEditable(false), isEditable, 'talent')

  const _handleAvatarSave = ({ fileMeta, profilePicture }: { fileMeta: File; profilePicture: string }) => {
    toast.dismiss()
    toast
      .promise(dispatch(updateProfilePicture({ fileMeta, profilePicture })), {
        pending: details.eid ? 'Updating profile picture.' : 'Saving profile picture',
      })
      .then(async (response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (details.eid) {
            await dispatch(
              updateTalent({
                eid: details.eid,
                payload: { profile_image_eid: response.payload.profile_image_eid },
              })
            )
            toast.success('Profile picture saved.')
          } else {
            const talentDetails: iTalentProfileDetails = {
              ...details,
              profile_image_eid: response.payload.profile_image_eid,
              profileImage: response.payload.full_url,
            }
            dispatch(setDetails(talentDetails))
            toast.success('Profile picture updated.')
          }
        } else {
          toast.error(response.payload.message || 'Error uploading profile picture.')
        }
      })
  }

  return (
    <>
      <Stack
        padding={{ xxs: '12px 12px 0', sm: '0' }}
        direction={{ xxs: 'column-reverse', sm: isMobileWidth ? 'column-reverse' : 'row' }}
        alignItems={{ xxs: 'start', md: isMobileWidth ? 'start' : 'center' }}
        justifyContent={'center'}
        rowGap="14px">
        <Stack direction="row" minWidth={{ xxs: '100%', sm: 'auto' }} alignItems="center" columnGap="10px" rowGap="12px">
          <Box
            position="relative"
            minWidth={{ xxs: '50px', sm: isMobileWidth ? '50px' : '100px', md: isMobileWidth ? '50px' : '160px' }}
            width={{ xxs: '50px', sm: isMobileWidth ? '50px' : '100px', md: isMobileWidth ? '50px' : '160px' }}
            height={{ xxs: '50px', sm: isMobileWidth ? '50px' : '100px', md: isMobileWidth ? '50px' : '160px' }}
            onClick={() =>
              !agreementEl &&
              toggleModal(ModalPages.PROFILE_PICTURE_FORM, {
                modalProps: {
                  profilePictureForm: {
                    _handleAvatarSave: _handleAvatarSave,
                    avatar: details?.profileImage,
                  },
                },
              })
            }>
            <Avatar
              // size="xl"
              src={details.profileImage ?? undefined}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 100,
                border: '2px solid white',
              }}
              alt={details.display_name ?? ''}
              onClick={() => {
                if (isMobileWidth && !agreementEl)
                  toggleModal(ModalPages.PROFILE_PICTURE_FORM, {
                    modalProps: {
                      profilePictureForm: {
                        _handleAvatarSave: _handleAvatarSave,
                        avatar: details?.profileImage,
                      },
                    },
                  })
              }}
            />
            <Button
              variant="outlined"
              sx={{
                position: 'absolute',
                bottom: 10,
                right: 0,
                borderRadius: '100%',
                height: '56px',
                width: '56px',
                display: { xxs: 'none', md: isMobileWidth ? 'none' : 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white !important',
              }}
              onClick={() =>
                toggleModal(ModalPages.PROFILE_PICTURE_FORM, {
                  modalProps: {
                    profilePictureForm: {
                      _handleAvatarSave: _handleAvatarSave,
                      avatar: details?.profileImage,
                    },
                  },
                })
              }
              aria-label="change profile photo">
              <Icon.Camera size="20px" />
            </Button>
          </Box>
          <Box paddingX="24px">
            {isEditable ? (
              <Textfield
                name="display_name"
                placeholder="Talent Name"
                sx={{
                  [`.${outlinedInputClasses.root} .${outlinedInputClasses.input}`]: {
                    backgroundColor: 'white',
                    padding: '6px',
                    fontSize: '24px',
                  },
                }}
              />
            ) : (
              <Typography
                fontSize={`${GlobalStyles.FONT_SIZES.XTRA_LARGE}px`}
                color={GlobalStyles.MONO_BLACK}
                onClick={() => handleSetIsEditable(true)}>
                {details.display_name}
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack
          flex={1}
          direction="row"
          alignSelf={{ xxs: 'end', md: isMobileWidth ? 'end' : 'start' }}
          alignItems={'flex-end'}
          justifyContent={'flex-end'}>
          {isEditable ? (
            <Stack direction="row" columnGap="12px">
              <Button
                variant="outlined"
                onClick={() => {
                  if (talentId) {
                    handleSetIsEditable(false)
                  } else {
                    router.push(`/${workspace}/directory/talent-profiles`)
                  }
                }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={doSubmit(onSubmit)} disabled={apiStatus === 'pending'}>
                <Icon.Save />
                Save
              </Button>
            </Stack>
          ) : agreementEl ? (
            <></>
          ) : (
            <Button
              variant="outlined"
              sx={{ width: '40px', height: '40px', padding: 0 }}
              onClick={() => handleSetIsEditable(true)}
              aria-label="edit talent">
              <Icon.Edit2 size={16} />
            </Button>
          )}
        </Stack>
      </Stack>
      {agreementEl}
      <Paper>
        <Row columnGap="12px">
          <Stack columnGap="12px" direction="row" paddingY={paddingY} paddingX={paddingX}>
            <Icon.Briefcase size={20} color={GlobalStyles.SLATE_500} />
            <Typography
              color={GlobalStyles.SLATE_500}
              fontSize={`${GlobalStyles.FONT_SIZES.BASE}px`}
              fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
              ABOUT ME
            </Typography>
          </Stack>
        </Row>
        <form>
          <Row noBorder>
            <CommonGrid
              borderRight
              label="Role"
              value={
                isEditable ? (
                  <Stack>
                    <Textfield
                      name="role"
                      inputProps={{
                        'aria-label': 'role',
                      }}
                    />
                  </Stack>
                ) : (
                  details.role
                )
              }
            />
            <CommonGrid
              label="Based in"
              value={
                isEditable ? (
                  <AutoCompleteLocation defaultValue={formatLocation(details.location)} onChange={locationChange} />
                ) : (
                  formatLocation(details.location)
                )
              }
            />
          </Row>
          <Row>
            <Grid
              item
              xxs={12}
              sm={isMobileWidth ? 12 : 4}
              lg={isMobileWidth ? 12 : 2}
              alignItems="flex-start"
              paddingY={paddingY}
              paddingX={paddingX}>
              <Stack direction="row" alignItems="center" columnGap="4px">
                <Typography variant="caption" color={GlobalStyles.MONO_BLACK} fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
                  Bio
                </Typography>
                <Box display={{ xxs: 'none', sm: 'initial' }}>
                  <Tooltip text="" />
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xxs={12}
              sm={isMobileWidth ? 12 : 8}
              lg={isMobileWidth ? 12 : 10}
              alignItems="flex-start"
              display="flex"
              paddingY={paddingY}
              paddingX={paddingX}>
              {isEditable ? (
                <Textfield multiline name="bio" style={{ width: '100%' }} aria-label="bio" />
              ) : (
                <Typography color={GlobalStyles.SLATE_700}>{details.bio}</Typography>
              )}
            </Grid>
          </Row>
          {features.isSocialCampaignsEnabled && settings?.social_metrics && !router.pathname.includes('create') && <SocialCampaign />}
        </form>
      </Paper>
    </>
  )
}
