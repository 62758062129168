import { ReactEditor, useFocused, useReadOnly, useSelected, useSlate } from 'slate-react'
import { ElementProps } from 'src/andromeda/RichEditor/tools'
import { LeafType } from 'remark-slate'
import { Stack, Fade, Popper, Typography } from '@mui/material'
import { CSSProperties, useMemo, useRef, useState } from 'react'
import { Avatar, GlobalStyles } from '@andromeda'
import { getInitials } from '@utils/functions/helperFunctions'
import { Transforms } from 'slate'
import { MentionElement } from './mention-types'
import { Types } from '@orbit'

const Mention = ({ attributes, children, mentions, element }: ElementProps) => {
  const [showDetails, setShowDetails] = useState(false)
  const anchorEl = useRef<HTMLElement | null>(null)
  const selected = useSelected()
  const focused = useFocused()
  const editor = useSlate()
  const isReadOnly = useReadOnly()

  const style: React.CSSProperties = {
    padding: '2px 6px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    borderRadius: '20px',
    fontSize: '0.9em',
    userSelect: 'none',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  const firstElement = element.children[0] as LeafType & { underline?: boolean }
  const { fullName, uid, link, mentionType } = (element as MentionElement) || {}
  const mentionFullName = link && fullName ? fullName.slice(1, fullName.length) : fullName

  if (firstElement.bold) {
    style.fontWeight = 'bold'
  }

  if (firstElement.italic) {
    style.fontStyle = 'italic'
  }

  if (firstElement.underline) {
    style.textDecoration = 'underline'
  }

  const prefix = useMemo(() => {
    switch (mentionType) {
      case 'user':
        return '@'
      case 'talent':
        return '%'
      default:
        return ''
    }
  }, [mentionType])

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (isReadOnly && !!link) {
      window.open(link, '_blank')
    } else {
      const path = ReactEditor.findPath(editor, element)
      Transforms.select(editor, path)
    }
  }

  return (
    <span
      {...attributes}
      ref={anchorEl}
      className="inline-block relative"
      contentEditable={false}
      data-id={`mention-${mentionFullName?.replace(' ', '-')}`}
      data-user-id={uid}
      style={{
        ...style,
        backgroundColor: mentionType === 'user' ? '#e4e4e4' : 'transparent',
        border: mentionType === 'talent' ? '1px solid #c8c8c8' : 'none',
        cursor: !!link ? 'pointer' : 'unset',
      }}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
      onClick={handleClick}>
      {link ? fullName : `${prefix}${fullName}`}
      {children}
      {mentionType && ['user', 'talent'].includes(mentionType) && (
        <Popper
          open={showDetails}
          anchorEl={anchorEl?.current}
          popperOptions={{ placement: 'top-start' }}
          transition
          style={{ zIndex: 1251, paddingBottom: '.4rem' }}
          onMouseLeave={() => setShowDetails(false)}>
          {({ TransitionProps }) => {
            let mention: (Partial<Types.User.iUser | Types.Talent.iTalent> & { _notExistsText?: string }) | null = null
            const { users, talents } = mentions || { users: [], talents: [] }
            const textStyle: CSSProperties = { textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.TINY }

            if (mentionType === 'user') {
              mention = users.find((u) => u.eid === uid) || { _notExistsText: 'User does not exist or got removed' }
            } else if (mentionType === 'talent') {
              mention = talents.find((t) => t.eid === uid) || { _notExistsText: 'Talent does not exist or got removed' }
            }

            return (
              <Fade {...TransitionProps}>
                <Stack
                  className="shadow-md"
                  direction="row"
                  alignItems="center"
                  columnGap="8px"
                  style={{
                    padding: `${GlobalStyles.PADDING_SIZES.xxs}px ${GlobalStyles.PADDING_SIZES.xs}px`,
                    backgroundColor: '#fff',
                    border: `1px solid ${GlobalStyles.SLATE_50}`,
                    borderRadius: '3px',
                  }}>
                  {!!mention?.eid ? (
                    <>
                      <Avatar
                        size={'xs'}
                        image={typeof mention?.profile_image === 'string' ? mention?.profile_image : mention?.profile_image?.full_url}
                        placeHolder={<Typography style={textStyle}>{getInitials(mentionFullName)}</Typography>}
                      />
                      <span className="inline-block">{mentionFullName}</span>
                    </>
                  ) : (
                    <Typography style={textStyle}>{(mention && mention._notExistsText) || 'Data does not exist'}</Typography>
                  )}
                </Stack>
              </Fade>
            )
          }}
        </Popper>
      )}
    </span>
  )
}

export default Mention
