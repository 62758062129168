import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Stack, Typography } from '@mui/material'
import { Types } from '@orbit/index'
import { useCreateJobSocialCampaign, useJobSocialCampaign, useSyncJobSocialCampaign } from '@utils/query/useJobSocialCampaign'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Textfield } from '../form/Textfield'
import { DevTool } from '@hookform/devtools'

const TILES = [
  {
    value: '2M',
    label: 'Impressions',
    key: 'impressions_num',
  },
  {
    value: '1.9M',
    label: 'Reach',
    key: 'reach_num',
  },
  {
    value: '361',
    label: 'Posts & Videos',
    key: 'posts_num',
  },
  {
    value: '36',
    label: 'Creators',
    key: 'influencers_num',
  },
]

const validationSchema = yup.object().shape({
  hashtags: yup.string().required('Hashtag is required'),
  requirements: yup
    .object()
    .shape({
      Twitter: yup.boolean(),
      Instagram: yup.boolean(),
    })
    .test({
      message: 'At least one platform is required',
      test: (val) => {
        return !Object.values(val).every((v) => !v)
      },
    }),
})
export const Campaign = () => {
  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      hashtags: undefined,
      requirements: undefined,
    },
  })

  const [isCampaignStatsVisible, setIsCampaignStatsVisible] = useState(true)
  const [hashtag, setHashtag] = useState('')

  const createJobCampaignMutation = useCreateJobSocialCampaign()
  const { data } = useJobSocialCampaign()
  const snycJobCampaignMutation = useSyncJobSocialCampaign()

  const onSubmit: SubmitHandler<yup.InferType<typeof validationSchema>> = (data) => {
    const payload: Types.Job.iCreateCampaignPayload = data as unknown as Types.Job.iCreateCampaignPayload

    Object.keys(data.requirements).forEach((key) => {
      const platform = key as keyof typeof data.requirements
      payload.requirements[platform] = payload.requirements[platform] ? 1 : 0
    })

    createJobCampaignMutation.mutate(payload)
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} placement="top-left" />
      <Stack>
        <Stack
          direction="row"
          sx={{
            '>div': {
              padding: '12px 24px',
            },
          }}>
          <Stack direction="row" flex={1} justifyContent="space-between">
            <Stack direction="row" alignItems="center" columnGap="4px">
              <Typography fontSize="14px" fontWeight={500} letterSpacing="-0.14px" color={GlobalStyles.MONO_BLACK}>
                Campaign # Hashtag
              </Typography>
              <Tooltip text="hashtag" />
              {data?.hashtags ? (
                <Typography fontSize="14px" letterSpacing="-0.14px" color={GlobalStyles.SLATE_700}>
                  {data.hashtags}
                </Typography>
              ) : (
                <Textfield
                  name="hashtags"
                  onChange={(e) => {
                    setHashtag(e.target.value)
                  }}
                />
              )}
              {!data && (
                <Button onClick={form.handleSubmit(onSubmit)} disabled={createJobCampaignMutation.isPending}>
                  Create
                </Button>
              )}
            </Stack>
            {!data && (
              <Stack>
                <Stack direction="row" alignItems="center" columnGap="4px">
                  <Typography fontSize="14px" fontWeight={500} letterSpacing="-0.14px" color={GlobalStyles.MONO_BLACK}>
                    Required Platforms
                  </Typography>
                  <FormGroup sx={{ flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox {...form.register('requirements.Twitter')} />} label="Twitter" />
                    <FormControlLabel control={<Checkbox {...form.register('requirements.Instagram')} />} label="Instagram" />
                  </FormGroup>
                </Stack>
                {form.formState.errors.requirements?.root && (
                  <FormHelperText error>{form.formState.errors.requirements.root.message}</FormHelperText>
                )}
              </Stack>
            )}
          </Stack>
          {data && (
            <Stack direction="row" justifyContent="end" flex={1}>
              {/* Hidden for now as per requirements */}
              {/* <Button
                variant="text"
                startIcon={<Icon.BarChart color={GlobalStyles.PRIMARY_500} size={20} />}
                onClick={() => setIsCampaignStatsVisible((prev) => !prev)}>
                {`${isCampaignStatsVisible ? 'Hide' : 'View'} Campaign Stats`}
              </Button> */}
              <Button
                variant="outlined"
                startIcon={<Icon.Repeat color={GlobalStyles.PRIMARY_500} size={16} />}
                disabled={snycJobCampaignMutation.isPending}
                sx={{ textTransform: 'uppercase', fontSize: '13px', paddingY: '4px', lineHeight: '22px', minHeight: 'unset' }}
                onClick={() => snycJobCampaignMutation.mutate()}>
                Fetch Analytics
              </Button>
            </Stack>
          )}
        </Stack>
        {data && isCampaignStatsVisible && (
          <Stack padding="12px 24px">
            <Typography fontSize="18px" color={GlobalStyles.SLATE_700} textAlign="center" sx={{ paddingY: '6px' }}>
              Campaign Stats
            </Typography>
            <Stack
              direction="row"
              rowGap="8px"
              flexWrap="wrap"
              justifyContent="center"
              sx={{
                '>div': {
                  borderRight: `1px solid ${GlobalStyles.SLATE_100}`,
                },
                '>div:last-child': {
                  borderRight: 'none',
                },
              }}>
              {TILES.map(({ value, label, key }) => (
                <Stack key={label} minWidth="146px" boxSizing="border-box" alignItems="center" padding="12px 24px">
                  <Typography color={GlobalStyles.MONO_BLACK} fontSize="16px" fontWeight={500} letterSpacing="-0.16px">
                    {key ? data?.[key as keyof Types.Job.iJobSocial] : value}
                  </Typography>
                  <Typography color={GlobalStyles.SLATE_700} fontSize="14px" letterSpacing="-0.14px">
                    {label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  )
}
